<template>
  <section id="yyzhgl" class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 医院综合管理解决方案 -</h2>
            <h4 class="sub-title">Hospital integrated management solution</h4>
          </div>
        </div>
      </div>
      <div class="row">

        <div v-for="(item, index) in list" :key="index" class="col-lg-4 col-md-6">
          <div class="service-box wow flipInX">
            <div class="service-icon">
              <span class="service-icon-bg">
                <i class="fa" :class="item.icon" aria-hidden="true"></i>
              </span>
            </div>
            <div class="service-content">
              <h4 class="mb10 bold ft18">{{ item.title  }}</h4>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '人事管理',
          content: '人员管理、岗位规划、考勤管理、薪资管理、保险福利、合同管理、报表统计、规章制度、科室管理',
          icon: 'fa-plug'
        },
        {
          title: '财务管理',
          content: '收费标准、收扣退费、批量收费、收费分析、财政收入管理、工资管理、支出审批、经营分析',
          icon: 'fa-puzzle-piece'
        },
        {
          title: '移动办公',
          content: '电子邮件、即时通讯、任务协同、日程排班、工作汇报、手机考勤、请假打卡、移动审批、数据填报、决策分析',
          icon: 'fa-random'
        },
        {
          title: '总务管理',
          content: '设备管理、设备入库、设备出库、设备维护、设备报修、固定资产、统计分析',
          icon: 'fa-rss-square'
        },
        {
          title: '病历管理',
          content: '病历数据库、医疗知识库、医嘱记录、住院病历、术前记录、手术记录、术后总结、病程记录、出院小结、特殊病历分析',
          icon: 'fa-sliders'
        },
        {
          title: '服务指挥调度',
          content: '客服呼叫、电话协调、服务申请、设备申请、申请审批、服务移交、短信管理、职能公告、科室信息、留言板、一键搜索、院长专线、工作情况统计、服务状态统计、满意度统计、申请科室统计、部门工作量统计、回访统计、坐席工作量统计、电话协调统计、办结时限统计',
          icon: 'fa-tasks'
        },
        {
          title: '隐患管理',
          content: '隐患分级管控、隐患排查、电子巡查、隐患治理、预警推送',
          icon: 'fa-wifi'
        }
      ]
    }
  }
}
</script>

<style scoped>

.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 05 Service-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding-top: 80px;
  /* padding-bottom: 80px; */
}

.service-icon span.service-icon-bg i:before {
  font-size: 30px;
}

.service-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 24px 22px 0px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 22px 0px rgba(40, 44, 71, 0.12);
}

.service-icon {
  width: 30%;
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.icon {
  width: 86px;
  height: 66px;
  background-color: #ff8a41;
}

.service-content {
  width: 68%;
  margin-left: auto;
}
.service-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}

.service-icon-bg {
  background-color: #ff8a41;
  width: 86px;
  height: 66px;
  padding-left: 15px;
  display: block;
  font-size: 33px;
  text-align: center;
  line-height: 2;
  color: #fff;
  border-radius: 0 8px 8px 0;
}

</style>
