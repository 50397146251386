<template>
  <section id="">

    <div class="container pt60 pb60">

      <div class="row">
        <div class="col-lg-12 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="w100 tc">
            <p class="ft30 bold">- 公司优势 -</p>
            <div class="mtb20">COMPANY ADVANTAGES</div>
          </div>
        </div>
      </div>

      <div class="row mt20">
        <div
          v-for="(item, index) in list" :key="index"
          class="col-md-3 text-center wow bounceInLeft">

          <div class="li">

            <!-- S正面 -->
            <div class="m_front">
              <div class="img" :style="{ backgroundImage: 'url(' +item.src + ')' }"></div>
              <div class="text">
                  <h2>{{ item.title  }}</h2>
                  <div class="h"></div>
                  <h3>{{ item.content  }}</h3>
              </div>
            </div>
            <!-- E正面 -->

            <!-- S反面 -->
            <div class="m_back" :style="{ backgroundImage: 'url(' +item.src + ')' }">
              <div class="inner text2">
                <h4>{{ item.title  }}</h4>
                <p>{{ item.content  }}</p>
              </div>
            </div>
            <!-- S反面 -->

          </div>

        </div>
      </div>

    </div>

  </section>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '经验丰富',
          content: '专注于数据可视化一站式服务，匠心铸就好口碑',
          src: '/img/why_img5.jpg'
        },
        {
          title: '专业团队',
          content: '拥有专业的产品策划、创意设计、技术开发、产品研发、售后服务团队',
          src: '/img/why_img6.jpg'
        },
        {
          title: '核心技术',
          content: '拥有专业的产品策划、创意设计、技术开发、产品研发、售后服务团队',
          src: '/img/why_img7.jpg'
        },
        {
          title: '一站式服务',
          content: '提供咨询、设计、售后等一站式服务',
          src: '/img/why_img8.jpg'
        }
      ]
    }
  },
  methods: {
  }
}
</script>
<style scoped>

.li {
  position: relative;
  cursor: pointer;
  z-index: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #00000014;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  height: 530px;
}

.li .m_front {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  border: 1px solid #EEEEEE;
  padding: 312px 60px 107px;
  background: #F9F9F9;
  background: -o-linear-gradient(45deg,#F9F9F9 0%,#ffffff 100%);
  background: linear-gradient(45deg,#F9F9F9 0%,#ffffff 100%);
}

.li:hover .m_front {
  transform: rotateY(180deg);
}
.li:hover .m_back {
  transform: rotateY(0deg);
}

.m_front, .m_back {
  height: 100%;
  -webkit-transition: -webkit-transform .7s cubic-bezier(0.4,0.2,0.2,1);
  transition: -webkit-transform .7s cubic-bezier(0.4,0.2,0.2,1);
  -o-transition: transform .7s cubic-bezier(0.4,0.2,0.2,1);
  transition: transform .7s cubic-bezier(0.4,0.2,0.2,1);
  transition: transform .7s cubic-bezier(0.4,0.2,0.2,1), -webkit-transform .7s cubic-bezier(0.4,0.2,0.2,1);
  transition: transform .7s cubic-bezier(0.4,0.2,0.2,1),-webkit-transform .7s cubic-bezier(0.4,0.2,0.2,1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.li .img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 97px;
  z-index: 0;
}

.m_front .img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
}

.li .text {
  position: relative;
  z-index: 1;
}

.li .m_front .text {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}

.li h2 {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  /* position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); */
  text-align: center;
}

.li .h {
  width: 15px;
  height: 2px;
  background-color: #707070;
  margin: 21px auto 24px;
  -webkit-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}

.li h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #333333;
  opacity: 0.5;
  text-align: center;
  height: 75px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}


.li .m_back {
  background-size: cover;
  background-position: center;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.m_back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.m_back:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear,left top, left bottom,from(rgba(0,0,0,0)),to(#000000));
  background: -o-linear-gradient(top,rgba(0,0,0,0) 0%,#000000 100%);
  background: linear-gradient(180deg,rgba(0,0,0,0) 0%,#000000 100%);
}

.li .inner.text2 {
  text-align: left;
  padding: 0 30px;
}

.inner {
  bottom: 10%;
  position: absolute;
  left: 0;
  width: 100%;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  -webkit-transform: translateZ(60px) scale(0.94);
  transform: translateZ(60px) scale(0.94);
}

.li .inner.text2 h4 {
  color: #fff;
  font-size: 22px;
  text-align: left;
  padding-bottom: 10px;
  font-weight: bold;
  /* opacity: 0; */
}

.li .inner.text2 p {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: #fff;
  opacity: 0.5;
  height: 75px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

</style>