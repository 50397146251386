<template>
  <section id="">

    <div class="container pt60 pb60">

      <div class="row">
        <div class="col-lg-12 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="w100 tc">
            <p class="title ft30 bold">▶ 我们能提供的服务</p>
            <div class="mtb20">以数据为核心，结合创意设计、前端交互、技术研发等多维度，为客户搭建综合性数字可视化平台</div>
          </div>
        </div>
      </div>

      <div class="row mt20">
        <div
          v-for="(item, index) in list" :key="index"
          class="col-md-3 text-center wow bounceInLeft">

          <div class="services">
            <span class="icon"><img :src="item.src" alt=""></span>
            <div class="desc">
              <h3>{{ item.title  }}</h3>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
          </div>

        </div>
      </div>

    </div>

  </section>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '体验策划',
          content: '按行业分组的咨询师团队，深刻理解不同行业用户、不同使用场景对于可视化系统需求差异。',
          icon: 'fa-handshake-o',
          hash: 'yrjy',
          src: '/img/serve_01.png'
        },
        {
          title: '交互设计',
          content: '最IN的动效、最IN的视觉交互效果，强悍的技术支撑，开发模式符合国际主流技术配置。',
          icon: 'fa-desktop',
          hash: 'zxxjy',
          src: '/img/serve_02.png'
        },
        {
          title: '视觉设计',
          content: '一线设计师，国际化视野，把握国际设计趋势，响应式解决方案，视觉是我们的表达方式。',
          icon: 'fa-mortar-board',
          hash: 'gxjy',
          src: '/img/serve_03.png'
        },
        {
          title: '前端开发',
          content: '全方位自有技术团队，全自主可控技术体系，支持深度个性化定制、系统未来扩展维护。',
          icon: 'fa-handshake-o',
          hash: 'yrjy',
          src: '/img/serve_04.png'
        },
        {
          title: '可视化图表',
          content: '超过50中丰富的可视化组件，包括常用的柱形图、折线图、雷达图等，支持可视化效果定制，为您呈现多样数据信息提供全面支持。',
          icon: 'fa-desktop',
          hash: 'zxxjy',
          src: '/img/serve_05.png'
        },
        {
          title: '数据接入',
          content: '强大的数据接入能力，支持丰富的数据源接入，包含各种关系型数据库、文本数据源、大数据平台、API接口等。',
          icon: 'fa-mortar-board',
          hash: 'gxjy',
          src: '/img/serve_06.png'
        },
        {
          title: '炫酷大屏',
          content: '基于不同行业和需求，结合丰富的可视化组件，个性化设计符合您需求的可视化大屏。',
          icon: 'fa-desktop',
          hash: 'zxxjy',
          src: '/img/serve_07.png'
        },
        {
          title: '权限管控',
          content: '企业组织级别管理，包括项目空间数据隔离、基于角色和用户的授权机制、资源操作权限与数据行权限灵活设置，充分保证您的数据安全。',
          icon: 'fa-mortar-board',
          hash: 'gxjy',
          src: '/img/serve_08.png'
        }
      ]
    }
  },
  methods: {
  }
}
</script>
<style scoped>

.services {
  position: relative;
  cursor: pointer;
  z-index: 0;
  padding: 60px 20px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #00000014;
}

.services:before{
	position:absolute;
	content:"";
	left:0;
	bottom:0;
	height:3px;
	width:0%;
	background:#798eea;
	opacity:0;
	visibility:hidden;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.services:hover:before{
	width:100%;
	opacity:1;
	visibility:visible;
}
.services:hover {
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}
.services:hover .icon {
  transform: scale(1.3);
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
}

.services .icon {
  position: relative;
  margin-bottom: 30px;
  display: inline-block; 
}
.services .icon i {
  font-size: 30px;
  color: #798eea;
  z-index: 1; 
}
.services .icon img {
  width: 70px;
}
.services .desc h3 {
  font-size: 20px;
  font-weight: bold; 
  color: #303133;
  font-family: "Nunito", Arial, sans-serif;
  font-weight: bold;
  margin: 0 0 20px 0;
  line-height: 1.3;
}
.services .desc p {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
  color: rgb(61, 61, 61);

  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

</style>