<template>
  <section id="school" class="ws-section-spacing about-bg bginput">
    <div class="container">
      <div class="row about-content">
        <div class="col-lg-12 col-md-12 mb80">
          <h3 class="tc mb30 bold">- 报表能力全面 -</h3>
          <p class="tc">借助Excel的强大，赋予其“设计器”的责任，从此WEB报表更丰富、更灵活，降低企业报表开发门槛！</p>
        </div>
        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="w90 mauto">
            <div class="left-title mb20">
              <p class="title ft18 bold">▶ Excel用户也能设计企业报表</p>
            </div>
            <ul>
              <li class="about-point"> 
                <span class="dot"></span>集成Excel和ECharts，使得Insight具有丰富的展现力、强大的互动性（基于单元格和对象的数据模型）、超级灵活的布局能力
              </li>
              <li class="about-point"> 
                <span class="dot"></span>深度整合Excel的现有能力，在Excel界面中完成报表、数据分析应用的设计
              </li>
            </ul>
          </div>
          <div class="w90 mauto mt20">
            <div class="left-title mb20">
              <p class="title ft18 bold">▶ 企业报表解决方案专家</p>
            </div>
            <ul>
              <li class="about-point"> 
                <span class="dot"></span>简单、高效、智能，可快速搭建的企业级Web报表
              </li>
              <li class="about-point"> 
                <span class="dot"></span>从报表开发的数据准备、样式设计、数据计算、数据可视化到互动逻辑、共享发布
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInLeft">
          <div class="w90 mauto tc">
            <img class="h100 w100" src="/img/bbsj_c2.png" alt="img">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  
}
</script>
<style scoped>
/* ----------------------------------------------------------------
[ 06 About-section ]
-----------------------------------------------------------------*/
h2 {
    font-size: 33px;
    line-height: 48px;
    margin-bottom: 0px;
    font-weight: 700;
}

.about-bg {
  /* background-image: url('~@/assets/about-bg.png');
  background-repeat: no-repeat; */
  padding: 90px 0 45px 0;
}


.left-title {
  text-align: left;
}

.left-title .title {
  text-transform: capitalize;
}

.left-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  font-weight: 600;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  border: 2px solid #1559b7;
  display: inline-block;
  margin-right: 10px;
}

.about-point {
  font-weight: 600;
  padding-bottom: 15px;
}

.btn {
  border-radius: 8px;
  background-color: #ff8a41;
  margin: 40px 0 30px 0;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 12px 40px;
  width: 50%;
}

.btn:hover {
  background-color: #596dee;
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

/* ----------------------------------------------------------------
     [ End About-section ]
-----------------------------------------------------------------*/
</style>