<template>
  <section id="nyfa" class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 农业环境监测解决方案 -</h2>
            <h4 class="sub-title">Solutions for agricultural environmental monitoring</h4>
          </div>
        </div>
      </div>
      <div class="row">

        <div v-for="(item, index) in list" :key="index" class="col-lg-4 col-md-6">
          <div class="service-box wow flipInX">
            <div class="service-icon">
              <span class="service-icon-bg">
                <i class="fa" :class="item.icon" aria-hidden="true"></i>
              </span>
            </div>
            <div class="service-content">
              <h4 class="mb10 bold ft18">{{ item.title  }}</h4>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '信息管理',
          content: '政务新闻、通知公告、规章制度、学习活动、检查通报',
          icon: 'fa-address-book'
        },
        {
          title: '移动办公',
          content: '电子邮件、即时通讯、任务协同、日程安排、工作汇报、手机考勤、请假打卡、移动审批、数据填报、决策分析',
          icon: 'fa-desktop'
        },
        {
          title: '数据监测',
          content: '视频监测、种植监测、养殖监测、虫情测报分析、气象监测分析、土壤墒情监测、数据异常报警、历史监测数据、农产品质量监测、农产品安全监测',
          icon: 'fa-cube'
        },
        {
          title: '生产管理',
          content: '种植作物管理、养殖品种管理、生产工艺管理、生产工序管理、生产工位管理、智能设备管理、专业知识库、农友圈、农技推广',
          icon: 'fa-fax'
        },
        {
          title: '仓库管理',
          content: '类别管理、物品管理、用品管理、采购管理、固定资产、采购计划、入库、库存、出库、数据导入、统计分析',
          icon: 'fa-home'
        },
        {
          title: '组织架构',
          content: '监管机构管理、农业舆情管理、农资经营许可管理、农业执法管理、农业标准管理、品质控制管理',
          icon: 'fa-mail-reply-all'
        },
        {
          title: '大屏数据管理',
          content: '实时监测数据、农产品数据、产业主体数据、三品一标数据、农产品销售数据、农产品品牌分析数据、观光产业数据、评估/报告分析、农业产业链分析',
          icon: 'fa-mail-reply-all'
        }
      ]
    }
  }
}
</script>

<style scoped>

.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 05 Service-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding-top: 80px;
  /* padding-bottom: 80px; */
}

.service-icon span.service-icon-bg i:before {
  font-size: 30px;
}

.service-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 24px 22px 0px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 22px 0px rgba(40, 44, 71, 0.12);
}

.service-icon {
  width: 30%;
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.icon {
  width: 86px;
  height: 66px;
  background-color: #ec5a4f;
}

.service-content {
  width: 68%;
  margin-left: auto;
}
.service-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}

.service-icon-bg {
  background-color: #4e7cdd;
  width: 86px;
  height: 66px;
  padding-left: 15px;
  display: block;
  font-size: 33px;
  text-align: center;
  line-height: 2;
  color: #fff;
  border-radius: 0 8px 8px 0;
}

</style>
