<template>
  <div>
    <Section />
    <Modular2 />
    <Modular3 />
    <Modular6 />
  </div>
</template>
<script>
import Section from './components/section/Section2.vue'
import Modular1 from './components/modular1/index.vue'
import Modular2 from './components/modular2/index.vue'
import Modular3 from './components/modular3/index.vue'
import Modular6 from './components/modular6/index.vue'

export default {
  components: {
    Section,
    Modular1,
    Modular2,
    Modular3,
    Modular6
  },
  //参数不存在对象时
  mounted() {
    var query = this.$route.query;
    if (query.contact) {
      this.jumpClick('contact-section');
    }
  },
  methods: {
    jumpClick(hash){
      // document.querySelector(`#${hash}`).scrollIntoView(true)
      document.querySelector(`#${hash}`).scrollIntoView({
        behavior: "smooth", 
        // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
        block: "center",
        // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
        inline: "center" 
        // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
      })
    }
  }
}
</script>
<style scoped>

</style>