<template>
  <section id="school" class="ws-section-spacing about-bg bginput">
    <div class="container">
      <div class="row about-content">
        <div class="col-lg-12 col-md-12 mb80">
          <h3 class="tc mb30 bold">- 可视化仪表盘简洁美观 -</h3>
          <p class="tc">通过所见即所得的可视化仪表盘工具，实现企业数据的价值最大化，降低沟通成本，提高运营效率</p>
        </div>
        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="w90 mauto">
            <div class="left-title mb20">
              <p class="title ft18 bold">▶ 设计过程可视化，操作简单易上手</p>
            </div>
            <ul>
              <li class="about-point"> 
                <span class="dot"></span>编辑过程所见即所得，一屏完成自助分析
              </li>
              <li class="about-point"> 
                <span class="dot"></span>鼠标拖拉拽即可快速完成数据集准备、可视化探索和仪表盘的制作
              </li>
            </ul>
          </div>
          <div class="w90 mauto mt20">
            <div class="left-title mb20">
              <p class="title ft18 bold">▶ 丰富的可视化展示，轻松制作BI看板</p>
            </div>
            <ul>
              <li class="about-point"> 
                <span class="dot"></span>丰富的交互控件和图表组件，提供智能图形推荐
              </li>
              <li class="about-point"> 
                <span class="dot"></span>报表图形任意切换，且不受维度、度量的限制
              </li>
            </ul>
          </div>
          <div class="w90 mauto mt20">
            <div class="left-title mb20">
              <p class="title ft18 bold">▶ 支持多数据来源，布局灵活</p>
            </div>
            <ul>
              <li class="about-point"> 
                <span class="dot"></span>支持业务主题和自助数据集
              </li>
              <li class="about-point"> 
                <span class="dot"></span>双布局设计，跨屏发布到APP，支持流式布局
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInLeft">
          <div class="w100 mauto tc">
            <img class="h100 w100" src="/img/bbsj_c4.png" alt="img">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  
}
</script>
<style scoped>
/* ----------------------------------------------------------------
[ 06 About-section ]
-----------------------------------------------------------------*/
h2 {
    font-size: 33px;
    line-height: 48px;
    margin-bottom: 0px;
    font-weight: 700;
}

.about-bg {
  /* background-image: url('~@/assets/about-bg.png');
  background-repeat: no-repeat; */
  padding: 90px 0 45px 0;
}


.left-title {
  text-align: left;
}

.left-title .title {
  text-transform: capitalize;
}

.left-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  font-weight: 600;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  border: 2px solid #1559b7;
  display: inline-block;
  margin-right: 10px;
}

.about-point {
  font-weight: 600;
  padding-bottom: 15px;
}

.btn {
  border-radius: 8px;
  background-color: #ff8a41;
  margin: 40px 0 30px 0;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 12px 40px;
  width: 50%;
}

.btn:hover {
  background-color: #596dee;
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

/* ----------------------------------------------------------------
     [ End About-section ]
-----------------------------------------------------------------*/
</style>