<template>
  <section id="djfa"  class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 党建方案介绍 -</h2>
            <h4 class="sub-title">Introduction to the Party building plan</h4>
          </div>
        </div>
      </div>
      <div class="row">

        <div v-for="(item, index) in list" :key="index" class="col-lg-4 col-md-6">
          <div class="service-box wow flipInX">
            <div class="service-icon">
              <span class="service-icon-bg">
                <i class="fa" :class="item.icon" aria-hidden="true"></i>
              </span>
            </div>
            <div class="service-content">
              <h4 class="mb10 bold ft18">{{ item.title  }}</h4>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '党务平台',
          content: '党组织管理、党员管理、发展党员、党费管理、工作计划、政治生日、干部管理、党建研究、先进党支部管理、模范和公益',
          icon: 'fa-address-book'
        },
        {
          title: '活动平台',
          content: '三会一课、主题党日、谈心谈话、中心组学习、结对共建、民主生活会、民主评议会、组织生活会、双结对、联建共建',
          icon: 'fa-desktop'
        },
        {
          title: '宣传平台',
          content: '党建要闻、政策法规、会议精神、重要讲话、党务公开模范风采',
          icon: 'fa-cube'
        },
        {
          title: '学习平台',
          content: '资料库、试题库、师资库、课程管理、试卷批阅、学习监督、学习进度及成绩查询、直播学习',
          icon: 'fa-fax'
        },
        {
          title: '互动平台',
          content: '直播间、视频会议、投票选举、知识问答、党员论坛、问卷调研、调研问题管理、分数等级设置',
          icon: 'fa-home'
        },
        {
          title: '日常办公平台',
          content: '通知公告、任务安排、内部邮件、公共网盘、物品管理、党员位置上报、党员日报、活动室预约',
          icon: 'fa-mail-reply-all'
        },
        {
          title: '民生服务平台',
          content: '微心愿、走帮服、党员捐款、精准扶贫、志愿服务、建言献策、党员干部进社区',
          icon: 'fa-home'
        },
        {
          title: '监督平台',
          content: '活动督办、任务督办、学习督办、书记信箱、纪检信箱',
          icon: 'fa-home'
        },
        {
          title: '考核平台',
          content: '党组织考核-党组织积分、党组织奖惩；党员考核-党员积分、民主评议、党员奖惩',
          icon: 'fa-home'
        },
        {
          title: '大数据平台',
          content: '党员综合统计、党员活动情况统计、宣传情况统计、党建地图、党费收缴统计、结对共建情况统计、学习完成情况统计、党建数据大屏、谈心谈话情况统计、在线时长统计、党组织工作记录本',
          icon: 'fa-home'
        },
      ]
    }
  }
}
</script>

<style scoped>

.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 05 Service-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding-top: 80px;
  /* padding-bottom: 80px; */
}

.service-icon span.service-icon-bg i:before {
  font-size: 30px;
}

.service-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 24px 22px 0px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 22px 0px rgba(40, 44, 71, 0.12);
}

.service-icon {
  width: 30%;
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.icon {
  width: 86px;
  height: 66px;
  background-color: #ff8a41;
}

.service-content {
  width: 68%;
  margin-left: auto;
}
.service-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}

.service-icon-bg {
  background-color: #f2bb51;
  width: 86px;
  height: 66px;
  padding-left: 15px;
  display: block;
  font-size: 33px;
  text-align: center;
  line-height: 2;
  color: #fff;
  border-radius: 0 8px 8px 0;
}

</style>
