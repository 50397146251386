<template>
  <section id="zxxjy" class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 中小学教育方案功能介绍 -</h2>
            <h4 class="sub-title">Introduction to the functions of primary and secondary education programs</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="(item, index) in list" :key="index" class="col-lg-4 col-md-6">
          <div class="service-box wow flipInX">
            <div class="service-icon">
              <span class="service-icon-bg"><i class="fa" :class="item.icon"></i></span>
            </div>
            <div class="service-content">
              <h4 class="mb10 bold ft18">{{ item.title  }}</h4>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '行政审批',
          content: '请假审批、出差审批、采购审批、报销审批',
          icon: 'fa-sticky-note'
        },
        {
          title: '教务管理',
          content: '学生档案管理、学生综合评价、宿舍管理、教师档案管理、教师考勤、教师绩效管理、教师成长规划、教学团队管理、教学团队成长规划、教学评价、德育考核管理、工作管理、教师考核',
          icon: 'fa-users'
        },
        {
          title: '教学管理',
          content: '班级管理、考试管理、资料管理、课程管理、教研活动、教学任务',
          icon: 'fa-pencil-square'
        },
        {
          title: '总务管理',
          content: '报修管理、医务管理、类别管理、物品管理、用品管理、食堂采购、固定资产、采购计划、入库、库存、出库、借还、数据导入、统计分析',
          icon: 'fa-wrench'
        },
        {
          title: '食堂仓库',
          content: '食材入库、食材出库、食材库存查询、食材快检、食堂行政物品管理',
          icon: 'fa-cubes'
        },
        {
          title: '信息管理',
          content: '校园新闻、通知公告、规章制度、 教育活动、校园文化、检查通报',
          icon: 'fa-window-restore'
        },
        {
          title: '党建工作',
          content: '党建教育、组织工作、政策法规、支部生活、两学一做、校园先锋',
          icon: 'fa-street-view'
        },
        {
          title: '校园安全',
          content: '人脸识别、外来车辆人员管理、隐患管理',
          icon: 'fa-shield'
        },
        {
          title: '成长规划',
          content: '学生成长规划、教师成长规、学习资源、成绩统计分析',
          icon: 'fa-sign-language'
        },
        {
          title: '大数据分析',
          content: '学生成绩分析、学生综合分析、教师教学分析、师生出勤率分析、教学质量分析、听课质量分析、资产分析、能耗分析、隐患预警分析',
          icon: 'fa-bar-chart'
        }
      ]
    }
  }
}
</script>

<style scoped>

.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 05 Service-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding-top: 80px;
  /* padding-bottom: 80px; */
}

.service-icon span.service-icon-bg i:before {
  font-size: 30px;
}

.service-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 24px 22px 0px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 22px 0px rgba(40, 44, 71, 0.12);
}

.service-icon {
  width: 30%;
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.icon {
  width: 86px;
  height: 66px;
  background-color: #ff8a41;
}

.service-content {
  width: 68%;
  margin-left: auto;
}
.service-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}

.service-icon-bg {
  background-color: #71c44b;
  width: 86px;
  height: 66px;
  padding-left: 15px;
  display: block;
  font-size: 33px;
  text-align: center;
  line-height: 2;
  color: #fff;
  border-radius: 0 8px 8px 0;
}

</style>
