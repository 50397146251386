<template>
  <section id="contact-section" class="contact-section style-1 padding-tb">
    <div class="container wow bounceInUp">
      <div class="row">
        <div class="col-lg-8 col-12">
          <div class="section-header">
            <h2><span class="d-block">有问题吗?</span> <span class="theme-color">可以联系我们</span></h2>
          </div>
          <div class="section-wrapper">
            <form action="action">
              <div class="flex">
                <input v-model="form.name" type="text" class="flex1" placeholder="填写您的姓名">
                <input v-model="form.phone" type="text" class="flex2 ml20" placeholder="填写您的手机号">
              </div>
              <input v-model="form.product" type="text" placeholder="填写您的意向产品">
              <textarea v-model="form.content" placeholder="填写您的留言内容" rows="3"></textarea>
              <button type="submit" class="lab-btn"><span>提交 >></span></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: '',
        phone: '',
        product: '',
        content: ''
      }
    }
  },
  //参数不存在对象时
  mounted() {
    var query = this.$route.query;
    if (query?.title) {
      this.form.product = '意向产品 - ' + query.title;
    } else {
      this.form.product = '';
    }
  },
}
</script>
<style scoped>
.section-header {
  width: 100%;
  margin-bottom: 40px;
}
.section-header h2 {
  font-weight: bold;
}
.theme-color {
  color: #80bf63;
}

.contact-section.style-1 {
  /* width: 37%; */
  background: url('/img/contact-img.png') #fafbfc;
  background-position: right center;
  background-repeat: no-repeat;
  padding: 120px 0;
}
.contact-section.style-1 .section-wrapper form input, .contact-section.style-1 .section-wrapper form textarea {
  width: 100%;
  padding: 13px;
  border-radius: 0;
  border: none;
  margin-bottom: 20px;
  box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  color: #000;
}
input::input-placeholder{
	color: rgb(189, 188, 188);
  font-size: 14px;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{
	color: rgb(189, 188, 188);
  font-size: 14px;
}
input::-moz-placeholder, textarea::-moz-placeholder{
	color: rgb(189, 188, 188);
  font-size: 14px;
}
input::-moz-placeholder, textarea::-moz-placeholder{
	color: rgb(189, 188, 188);
  font-size: 14px;
}
input::-ms-input-placeholder, textarea::-ms-input-placeholder{
	color: rgb(189, 188, 188);
  font-size: 14px;
}


.contact-section.style-1 .section-wrapper form button[type=submit] {
  width: 220px;
  height: 50px;
  background: #80bf63;
  border: none;
  outline: none;
  padding: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.contact-section.style-1 .section-wrapper form button[type=submit]:hover {
  background: #44a6f1;
}
.lab-btn {
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
</style>