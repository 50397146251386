<template>
  <div class="colorlib-services colorlib-bg-white">
    <div class="container">
      <div class="row">

        <div
          v-for="(item, index) in list" :key="index"
          class="col-md-2 text-center animate-box wow bounceInLeft">

          <div 
            class="services"
            @click="jumpClick(item.hash)">
            <span class="icon"><i class="gray4 fa" :class="item.icon"></i></span>
            <div class="desc">
              <h3>{{ item.title  }}</h3>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
            <div class="mt40 gray9 ft14 ptb5 radius8 mauto">
              <a href="javascript:" @click="jumpClick(item.hash)">去了解 >></a>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '党建方案',
          content: '以党建引领为指引，运用互联网、云计算、大数据等信息化技术打造的综合性党建工作平台。从基础党务、党建宣教、组织生活、党群服务、监督考核、日常办公以及大数据分析等各个方面，为各级党政机关、事业单位、企业集团提供从设计、建设到落地的一站式智慧党建整体解决方案',
          icon: 'fa-handshake-o',
          hash: 'djfa'
        },
        {
          title: '政务办公',
          content: '通过建立智慧办公体系，搭建OA办公平台，实现对单位的统一信息化管理，打通单位内部各部门以及下属单位之间的信息沟通渠道，实现全单位无纸化办公，提高工作效率，保证单位工作中各类申请、审批、公文流转、邮件等各类业务流程的及时性、便捷性。通过智能会议的互通协作方式，方便单位间业务交流，提高沟通效率。同时，各类操作都有留痕，数据永久保存，方便后期追溯',
          icon: 'fa-desktop',
          hash: 'zwfa'
        },
        {
          title: '教育系统',
          content: '当前，全球信息技术呈加速发展趋势，信息技术在国民经济中的地位日益突出，信息资源也日益成为重要的生产要素。抢抓新一轮信息技术变革所带来的巨大机遇，进而促进经济社会又好又快地发展，对应急管理提出新的更高要求。结合教育发展实际和教育部、教育厅教育信息化2.0行动计划，建设教育管理专题一张图、教育台账、校园视频监控',
          icon: 'fa-mortar-board',
          hash: 'jyfa'
        },
        {
          title: '农业系统',
          content: '根据现代农业产业园现状，立足园区产业特色，建立以物联网、云计算、大数据、移动互联网、人工智能等信息技术为手段，采用客观数据获取及科学分析方法，建立覆盖生产、经营、管理、服务各环节的信息化管理体系，实现数字化、智能化管理，建成生产标准体系、质量安全体系、品牌营销体系、智能信息体系等现代化管理服务体系，加大智慧农业技术集成创新与成果推广，扩大智慧农业示范应用，从而加快园区成为产业特色鲜明、要素高度集中、设施装备先进、生产方式绿色、经济效益显著、辐射带动有力的生态农业产业园区',
          icon: 'fa-tint',
          hash: 'nyfa'
        },
        {
          title: '财务预决算',
          content: '以清结算财务中台为核心，提供全面、集中的经营财务管理，全场景业务接入，全链路业财数据沉淀，加速财务数字化转型，帮助财务高效、轻松地为政府创造更多管理价值',
          icon: 'fa-file-text',
          hash: 'csfa'
        },
        {
          title: '警务系统',
          content: '随着信息技术的快速发展，社会信息化程度的不断加深，犯罪活动呈现出复杂化、动态化、智能化的特征。利用大数据、人工智能等先进技术，分析数字世界并发现犯罪线索，实现对犯罪行为的早发现、早识别、早打击，已成为公安业务系统构建中的核心挑战。基于一站式大数据治理，全域接入公安、政府、互联网及泛在物联感知大数据，通过标准化治理、组织和服务方法体系，构建面向警务实战工作的大数据智能应用产品，为公安大数据治理、治安风险防控、督察一体化等应用提供全面支撑，实现案件数字侦查、动态犯罪打击、风险防控预警、安全态势感知和智能警务服务等方面的应用价值',
          icon: 'fa-sitemap',
          hash: 'jwfa'
        }
      ]
    }
  },
  methods: {
    jumpClick(hash){
      // document.querySelector(`#${hash}`).scrollIntoView(true)
      document.querySelector(`#${hash}`).scrollIntoView({
        behavior: "smooth", 
        // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
        block: "center",
        // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
        inline: "center" 
        // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
      })
    }
  }
}
</script>

<style scoped>
.animate-box {
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #00000014;
}
.animate-box:hover {
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}
.animate-box:hover .icon {
  transform: scale(1.3);
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
}

.colorlib-services {
  padding: 60px 0 60px 0;
  clear: both; 
}
.colorlib-bg-white {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-left: none;
  border-right: none; 
}
.services {
  position: relative;
  z-index: 0;
  padding: 20px; 
}

.animate-box:before{
	position:absolute;
	content:"";
	left:0;
	bottom:0;
	height:3px;
	width:0%;
	background:#798eea;
	opacity:0;
	visibility:hidden;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.animate-box:hover:before{
	width:100%;
	opacity:1;
	visibility:visible;
}

@media screen and (max-width: 768px) {
  .services {
    margin-bottom: 3em; 
  } 
}
.services .icon {
  position: relative;
  margin-bottom: 30px;
  display: inline-block; 
}
.services .icon i {
  font-size: 30px;
  color: #798eea;
  z-index: 1; 
}
.services .desc h3 {
  font-size: 18px;
  font-weight: bold; 
  color: #303133;
  font-family: "Nunito", Arial, sans-serif;
  /* font-weight: 400; */
  margin: 0 0 20px 0;
  line-height: 1.3;
}
.services .desc p {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
  /* color: rgb(61, 61, 61); */
  color: #333;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>