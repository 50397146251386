<template>
  <div class="colorlib-intro">
    <div class="container wow flipInY">
      <div class="row">
        <div class="col-md-12 center-title">
          <h2 class="title">- 企业资质 -</h2>
          <h4 class="sub-title">COMPANY NATURAL ENDOWMENT</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          企业资质企业资质企业资质
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>

.colorlib-intro {
  background: whitesmoke;
  clear: both; 
}

@keyframes scaleAnim2{
  0%{transform:scale(.3);opacity:0}
  50%{transform:scale(.7);opacity:.4}
  100%{transform:scale(1.4);opacity:0}
}
@keyframes scaleAnim1{
  0%{transform:scale(.3);opacity:0}
  50%{transform:scale(.6);opacity:.8}
  100%{transform:scale(1.6);opacity:0}
}

.center-title {
  text-align: center;
  padding: 60px 0 40px 0;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 20px;
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  /* line-height: 28px; */
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}
</style>