<template>
  <div class="colorlib-services colorlib-bg-white">
    <div class="container">
      <div class="row">

        <div
          v-for="(item, index) in list" :key="index"
          class="col-md-4 text-center animate-box wow bounceInLeft">

          <div 
            class="services"
            @click="jumpClick(item.hash)">
            <span class="icon"><i class="gray4 fa" :class="item.icon"></i></span>
            <div class="desc">
              <h3>{{ item.title  }}</h3>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
            <div class="mt40 w30 gray9 ft14 ptb5 radius8 mauto">
              <a href="javascript:" @click="jumpClick(item.hash)">去了解 >></a>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '幼儿教育',
          content: '“智脑结合、AI智能、数据分析、按需推送”的立体化智慧幼教生态系统。突出智慧保育在智慧幼儿园建设中的核心作用，推进互联网、大数据、人工智能等技术在幼儿园保教工作中的深度应用和融合，形成富有特色的园本课程和文化，实现各种应用融合，管理高效率;打通在园教育和家庭教育，家园互动深度化;技术融合创新，逐步实现能识别幼儿个体特征和学习情境、提供优质学习资源、记录和评价学习效果，使幼儿身心愉悦。本方案以阿波罗智慧幼教平台和园长助手为核心，将幼儿园智慧化建设设置了两端三方和九大应用场景部署',
          icon: 'fa-handshake-o',
          hash: 'yrjy'
        },
        {
          title: '中小学教育',
          content: '智慧校园从用户角度出发，打破传统业务系统边界，建设一个扁平化、易操作、搜索引擎式的统一开放可扩展的信息和服务平台。平台承载高校各类业务和信息，实现数据资产的可视化呈现、统计分析和辅助决策，培育高校教学、科研、管理和生活的线上智慧型社区生态。平台包括网上事务中心、大数据中心、网络社区平台、资讯中心、个人工作台、移动门户等方面内容',
          icon: 'fa-desktop',
          hash: 'zxxjy'
        },
        {
          title: '高校教育',
          content: '高等学校专业发展管理从高等学校专业特点出发，针对高水平建设任务多，专业发展情况难以把控的痛点，建立多层级结构化管理平台，帮助高等学校任务管理精细化、数据呈现可视化、评价指标动态化，全面实现职业教育建设数字化。平台包括专业（群）建设与规划、教学团队管理、学生职业发展规划、学习、教学日常化管理等方面内容',
          icon: 'fa-mortar-board',
          hash: 'gxjy'
        }
      ]
    }
  },
  methods: {
    jumpClick(hash){
      // document.querySelector(`#${hash}`).scrollIntoView(true)
      document.querySelector(`#${hash}`).scrollIntoView({
        behavior: "smooth", 
        // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
        block: "center",
        // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
        inline: "center" 
        // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
      })
    }
  }
}
</script>

<style scoped>
.animate-box {
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #00000014;
}
.animate-box:hover {
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}
.animate-box:hover .icon {
  transform: scale(1.3);
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
}

.colorlib-services {
  padding: 60px 0 60px 0;
  clear: both; 
}
.colorlib-bg-white {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-left: none;
  border-right: none; 
}
.services {
  position: relative;
  z-index: 0;
  padding: 20px; 
}

.animate-box:before{
	position:absolute;
	content:"";
	left:0;
	bottom:0;
	height:3px;
	width:0%;
	background:#798eea;
	opacity:0;
	visibility:hidden;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.animate-box:hover:before{
	width:100%;
	opacity:1;
	visibility:visible;
}

@media screen and (max-width: 768px) {
  .services {
    margin-bottom: 3em; 
  } 
}
.services .icon {
  position: relative;
  margin-bottom: 30px;
  display: inline-block; 
}
.services .icon i {
  font-size: 30px;
  color: #798eea;
  z-index: 1; 
}
.services .desc h3 {
  font-size: 18px;
  font-weight: bold; 
  color: #303133;
  font-family: "Nunito", Arial, sans-serif;
  /* font-weight: 400; */
  margin: 0 0 20px 0;
  line-height: 1.3;
}
.services .desc p {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
  /* color: rgb(61, 61, 61); */
  color: #333;

  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>