<template>
  <div>
    <Section />
    <Modular4 />
    <!-- <Modular5 /> -->

    <!-- S 医院综合管理方案 -->
    <Modular2 />
    <!-- E 医院综合管理方案 -->

    <!-- S 便民服务解决方案 -->
    <Modular3 />
    <!-- E 便民服务解决方案 -->

    <Modular1 />
  </div>
</template>

<script>
import Section from './components/section/Section.vue'
import Modular1 from './components/modular1/index.vue'
import Modular2 from './components/modular2/index.vue'
import Modular3 from './components/modular3/index.vue'
import Modular4 from './components/modular4/index.vue'
import Modular5 from './components/modular5/index.vue'

export default {
  components: {
    Section,
    Modular1,
    Modular2,
    Modular3,
    Modular4,
    Modular5
  },
  mounted() { 
    new this.$wow.WOW().init()
  }
}
</script>

<style scoped>

</style>
