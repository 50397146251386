<template>
  <section id="" class="solution">
    <div class="container pt60 pb60">

      <div class="row">
        <div class="col-lg-12 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="w100 tc">
            <p class="title ft30 bold white">▶ 助力行业解决方案</p>
            <div class="mtb20 white" style="opacity: .5;">助力企业通过数字可视化更便捷的认知和管理</div>
          </div>
        </div>
      </div>

      <div class="con mt20">

        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in list"
            :key="index"
            :class="index == currNav ? 'swiper-slide-thumb-active' : ''"
            class="swiper-slide"
            @click="handleLink(index, item)">
            <span>{{ item.title }}</span>
          </div>
        </div>

        <div class="swiper-container">
          <div v-for="(item, index) in list" :key="index">
            <div v-if="index == currNav" class="swiper-slide">
              <div class="left animated flipInX">
                <h2>{{ item.content.sub_title }}</h2>
                <div class="h"></div>
                <p>{{ item.content.describe }}</p>
              </div>
              <div class="right animated flipInX">
                <img :src="item.content.img" alt="">
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      currNav: 0,
      list: [
        {
          title: '智慧园区',
          content: {
            sub_title: '智慧园区解决方案',
            describe: '高度融合园区多种数据资源，运用3D技术制作园区三维模型，对园区产业、资产、基础设施、能效、安防等领域的关键指标进行综合监测分析，打造智慧园区管理一张图，实现更加高效科学的园区管理，全面提升园区管理水平。',
            img: '/img/solution_img.jpg'
          }
        },
        {
          title: '智慧城市',
          content: {
            sub_title: '智慧城市解决方案',
            describe: '城市3d数据可视化大屏，对整个城市进行三维可视化展示，利用智能互动显示技术轻松了解和查询整个建筑的优势。让管理者更全面地介绍建筑中的企事业单位信息，承载着人与环境沟通的重要作用。其设计风格体现了品牌和企业的理念和特点，可以与环境完美融合。',
            img: '/img/solution_img7.jpg'
          }
        },
        {
          title: '智慧政府',
          content: {
            sub_title: '智慧政府解决方案',
            describe: '广泛应用于振幅，通信，电力，军队指挥机构，多信息接收处理显示，注重需求为主、统筹兼顾、运用综合集成技术，已达到预期效果。',
            img: '/img/solution_img12.jpg'
          }
        },
        {
          title: '智慧交通',
          content: {
            sub_title: '智慧交通解决方案',
            describe: '基于数字孪生可视化技术，整合跨平台运营数据，全方位提升轨道交通服务质量，降低安全风险，助力轨交实现数字化升级。',
            img: '/img/solution_img9.jpg'
          }
        },
        {
          title: '智慧教育',
          content: {
            sub_title: '智慧教育解决方案',
            describe: '全国教育信息化中心，全方位展示教学质量，学习资源流通，师生家长角色互动，整个教育体系。呈现教育数据的相关视觉传达，并以更好的交互体验分析梳理，提供全面直观的教学状态，灵活调配教育资源决策。',
            img: '/img/solution_img14.jpg'
          }
        },
        {
          title: '智慧医疗',
          content: {
            sub_title: '智慧医疗解决方案',
            describe: '产业大脑，为政府打造烟台市特色生物医药产业提供产业决策和产业服务的数智化平台。',
            img: '/img/solution_img13.jpg'
          }
        },
        {
          title: '智慧电力',
          content: {
            sub_title: '智慧电力解决方案',
            describe: '用数据可视化的方法，实时监管电力设备在不同区域的电力状况，帮助电力行业优化能耗配置，为解决区域电桩设备布局、电力故障解决、电力资源调配等问题提供数据支撑。',
            img: '/img/solution_img8.jpg'
          }
        },
        {
          title: '智慧乡村',
          content: {
            sub_title: '智慧乡村解决方案',
            describe: '凭借优越的可视性和强烈的视觉冲击力，可及时传递政府动态、公告通知、项目流程等信息，方便村民办事，提升乡镇政府智能化服务水平，提高政府办公效率，也可以更好的展示乡村形象和特色文化等，大大提升乡镇的知名度和美誉度。',
            img: '/img/solution_img11.jpg'
          }
        },
        {
          title: '智慧工厂',
          content: {
            sub_title: '智慧工厂解决方案',
            describe: '数字可视化在智慧工厂、车间大屏、生产大屏搭建逻辑 数字驾驶舱基于已有信息化和数据建设成果，运用先进可视化、交互硬件设备，可视化软件技术和智能制造行业大数据，满足园区从数据采集、存储、计算、分析挖掘到可视化展示的一站式数据处理需求，并融合前沿AI科技机器深度学习自我进化，直至与园区自身状况深度契合，帮助园区借力大数据优势深化自身业务价值体系。赋能智慧农业、智慧工厂、智慧城市领域，为制造业提供解决方案。',
            img: '/img/solution_img14.jpg'
          }
        }
      ]
    }
  },
  methods: {
    handleLink(index, item) {
      this.currNav = index;
      console.log(item);
    },
  }
}
</script>

<style scoped>
.h {
  width: 30px;
  height: 3px;
  background-color: #373636;
  margin: 20px 0;
}
.solution {
  background-image: url(/img/solution_bg.jpg);
  background-size: cover;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-position: center;
}
.con {
  box-shadow: 0px 0px 10px #00000014;
}
.con .swiper-wrapper {
  background: #EDF1F7;
  border-radius: 6px 6px 0px 0px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.con .swiper-wrapper .swiper-slide {
  flex: 1;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.con .swiper-wrapper .swiper-slide span {
  height: 100%;
  line-height:74px;
  font-size: 17px;
}
.con .swiper-wrapper .swiper-slide-thumb-active span {
  border-bottom: 3px solid #2468F2;
  color:#2468F2;
  font-weight: bold;
}

.con .swiper-container {
  padding: 80px 90px;
  display: flex;
  border-radius: 0px 0px 6px 6px;
  background: #fff;
}
.swiper-slide {
  display: flex;
}
.swiper-slide .left {
  flex: 1;
  padding: 0 40px 0 0;
}
.swiper-slide .left h2 {
  font-weight: bold;
}
.swiper-slide .left p {
  margin-top: 30px;
}

.swiper-slide .right {
  flex: 1;
  padding: 0 0 0 40px;
}
.swiper-slide .right img {
  width: 100%;
  /* height: 100px; */
}
</style>