<template>
  <div class="colorlib-intro">
    <div class="container wow flipInY">
      <div class="row">
        <div class="col-md-12 center-title">
          <h2 class="title">- 幼儿教育方案介绍 -</h2>
          <h4 class="sub-title">Introduction to early childhood education program</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="plr70 tc">“智脑结合、AI智能、数据分析、按需推送”的立体化智慧幼教生态系统。突出智慧保育在智慧幼儿园建设中的核心作用，推进互联网、大数据、人工智能等技术在幼儿园保教工作中的深度应用和融合，形成富有特色的园本课程和文化，实现各种应用融合，管理高效率;打通在园教育和家庭教育，家园互动深度化;技术融合创新，逐步实现能识别幼儿个体特征和学习情境、提供优质学习资源、记录和评价学习效果，使幼儿身心愉悦。本方案以阿波罗智慧幼教平台和园长助手为核心，将幼儿园智慧化建设设置了两端三方和九大应用场景部署。</p>
        </div>
        <div class="col-md-12 mt40">
          <div class="Mbtn on"><div class="navbtn"><span><i class="fa fa-play" aria-hidden="true"></i></span></div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>

.colorlib-intro {
  background: whitesmoke;
  padding: 60px 0;
  clear: both; 
}
.colorlib-intro .container .box{
  text-align: center;
  padding: 0 200px;
}
.colorlib-intro .container .box h2 {
  font-size: 33px; font-weight: 700; color: #303133;
  font-family: "Nunito", Arial, sans-serif;
  margin: 0 0 20px 0;
  line-height: 1.3;
}
.colorlib-intro .container .box p {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
  color: gray;
  margin-bottom: 80px;
}

.Mbtn .navbtn{ 
  width: 80px; height: 80px; 
  line-height: 80px; text-align: center; 
  border-radius: 50%; border-radius: 50%; 
  position: relative; z-index: 2; 
  margin: 0 auto;
  cursor: pointer;
}
.Mbtn .navbtn:after{
  content:""; opacity:0;
  width:70px; height:70px; display:block;
  position:absolute; left:50%; top:50%;  z-index:1;
  margin-left:-35px; margin-top:-35px;
  background:#798eea;
  -moz-border-radius:50%;-webkit-border-radius:50%;border-radius:50%;
  animation:scaleAnim1 1.6s infinite linear;
}
.Mbtn .navbtn:before{
  content:""; opacity:0;
  width:80px; height:80px;  display:block;
  position:absolute; left:50%; top:50%; z-index:1;
  margin-left:-40px; margin-top:-40px;
  background:#98aaf7;
  -moz-border-radius:50%;-webkit-border-radius:50%;border-radius:50%;
  animation:scaleAnim2 1.6s infinite linear;
}
.Mbtn .navbtn:before{animation-delay:-.5s}

.Mbtn .navbtn span{ 
  width: 60px; height: 60px; display: block;
  line-height: 60px; font-size: 14px; color: #fff;
  position: absolute; left:50%; top:50%; z-index: 4;
  margin-left: -30px; margin-top: -30px;
}
.Mbtn .navbtn span:before{
  content:"";
  width:40px; height:40px; display:block;
  position:absolute; left:50%; top:50%; 
  border-radius: 50%;
  margin-left: -20px; margin-top: -20px; 
  /* background: #e1e1e1; */
}
.Mbtn .navbtn span:after{
  content:"";
  width:30px; height:30px; display:block;
  position:absolute; left:50%; top:50%;
  border-radius: 50%; 
  margin-left:-15px; margin-top: -15px; 
  line-height: 30px; 
  /* background: #fff; */
  color: #fff 
}

/* .Mbtn:hover .navbtn span:after{background: #079399;color: #fff;}
.Mbtn:hover .navbtn span:before{background: #2ca19d;} */

@keyframes scaleAnim2{
  0%{transform:scale(.3);opacity:0}
  50%{transform:scale(.7);opacity:.4}
  100%{transform:scale(1.4);opacity:0}
}
@keyframes scaleAnim1{
  0%{transform:scale(.3);opacity:0}
  50%{transform:scale(.6);opacity:.8}
  100%{transform:scale(1.6);opacity:0}
}

.center-title {
  text-align: center;
  padding-bottom: 40px;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 20px;
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  /* line-height: 28px; */
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

</style>