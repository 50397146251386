<template>
  <section id="jwfa" class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 警务系统解决方案 -</h2>
            <h4 class="sub-title">Police system solution</h4>
          </div>
        </div>
      </div>
      <div class="row">

        <div v-for="(item, index) in list" :key="index" class="col-lg-4 col-md-6">
          <div class="service-box wow flipInX">
            <div class="service-icon">
              <span class="service-icon-bg">
                <i class="fa" :class="item.icon" aria-hidden="true"></i>
              </span>
            </div>
            <div class="service-content">
              <h4 class="mb10 bold ft18">{{ item.title  }}</h4>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '三实管控',
          content: '标准地址、三实采集、监督检查、检查记录、整改记录、三维码门牌、社区三维码、责任划分、数据审核',
          icon: 'fa-address-book'
        },
        {
          title: '危险源点管控',
          content: '危险源点划分、危险源点统计、巡查计划、监督整改、常规任务、专项任务、信息统计',
          icon: 'fa-desktop'
        },
        {
          title: '督察一体化',
          content: '督察管理、各级通报、投诉通道、纪委案件查办、预警提示、执法监督考评、练兵比武、在线答疑、信访事项流转办理、信访当事人回访',
          icon: 'fa-cube'
        },
        {
          title: '政工管理',
          content: '人员管理、警力分布、光荣榜、人才库、岗位规划、考勤管理、规章制度、组织架构管',
          icon: 'fa-fax'
        },
        {
          title: '智慧OA',
          content: '通知公告、工作任务、工作日志、办公审批、工作计划',
          icon: 'fa-home'
        },
        {
          title: '公文管理',
          content: '发文管理、收文管理、文件登记、签收查询、办理意见、领导批示、手写签批、办理反馈、公文传阅、查询统计、图表统计、电子签章、多人会签、权限管理、公文交换、公文清稿',
          icon: 'fa-mail-reply-all'
        },
      ]
    }
  }
}
</script>

<style scoped>

.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 05 Service-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding-top: 80px;
  /* padding-bottom: 80px; */
}

.service-icon span.service-icon-bg i:before {
  font-size: 30px;
}

.service-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 24px 22px 0px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 22px 0px rgba(40, 44, 71, 0.12);
}

.service-icon {
  width: 30%;
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.icon {
  width: 86px;
  height: 66px;
  background-color: #a398e2;
}

.service-content {
  width: 68%;
  margin-left: auto;
}
.service-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}

.service-icon-bg {
  background-color: #a398e2;
  width: 86px;
  height: 66px;
  padding-left: 15px;
  display: block;
  font-size: 33px;
  text-align: center;
  line-height: 2;
  color: #fff;
  border-radius: 0 8px 8px 0;
}

</style>
