<template>
  <div>
    <Section />
    <Modular0 />
    <Modular1 />
    <Modular2 />
    <Modular3 />
    <Modular4 />
  </div>
</template>

<script>
import Section from './components/section/Section.vue'
import Modular0 from './components/modular0/index.vue'
import Modular1 from './components/modular1/index.vue'
import Modular2 from './components/modular2/index.vue'
import Modular3 from './components/modular3/index.vue'
import Modular4 from './components/modular4/index.vue'

export default {
  name: '',
  components: {
    Section,
    Modular0,
    Modular1,
    Modular2,
    Modular3,
    Modular4
  }
}
</script>

<style scoped>
</style>
