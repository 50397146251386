<template>
  <section id="" class="solution">
    <div class="container pt60 pb60">

      <div class="row">
        <div class="col-lg-12 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="w100 tc">
            <p class="title ft30 bold white">▶ 应用场景</p>
            <div class="mtb20 white" style="opacity: .5;">物联网平台支持海量设备稳定连接、实时在线，支持云端调用API低延时下发指令，提升各场景中用户体验</div>
          </div>
        </div>
      </div>

      <div class="con mt20">

        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in list"
            :key="index"
            :class="index == currNav ? 'swiper-slide-thumb-active' : ''"
            class="swiper-slide"
            @click="handleLink(index, item)">
            <span>{{ item.title }}</span>
          </div>
        </div>

        <div class="swiper-container">
          <div v-for="(item, index) in list" :key="index">
            <div v-if="index == currNav" class="swiper-slide">
              <div class="left animated flipInX">
                <h2>{{ item.content.sub_title }}</h2>
                <div class="h"></div>
                <p v-html="item.content.describe"></p>
              </div>
              <div class="right animated flipInX">
                <img :src="item.content.img" alt="">
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      currNav: 0,
      list: [
        {
          title: '共享充电宝',
          content: {
            sub_title: '共享充电宝',
            describe: '充电宝设备接入物联网平台后，可上报充电宝电量和借用状态等信息到物联网平台云端。充电宝用户扫码后，云端低延时向充电宝下发指令，使其弹出。同时，企业运营者能够实时获知充电宝的运行状况。',
            img: '/img/gxcdb.png'
          }
        },
        {
          title: '农业设备',
          content: {
            sub_title: '农业设备',
            describe: '使用各种传感器设备和通信网络，实时监控采集农业大棚中数据。传感器设备可通过RS485总线连接网关，再通过网关将其连接到物联网平台，实现在云端展示和管理数据。',
            img: '/img/nysb.png'
          }
        },
        {
          title: '智能家居',
          content: {
            sub_title: '智能家居',
            describe: '物联网平台广泛应用于智能家居电器，以智能插座为例，用户可远程查看插座使用情况，并控制其开关，避免因大功率电器过热，发生危险。您可使用一机一密的方式稳定接入海量设备，防止黑客批量攻击。',
            img: '/img/znjj.png'
          }
        },
        {
          title: '智能音箱',
          content: {
            sub_title: '智能音箱',
            describe: '播报音箱接入物联网平台后，用户扫码完成支付后，将支付金额实时通过音箱，向用户和商家进行语音播报。',
            img: '/img/znyx.png'
          }
        },
        {
          title: '智能媒体屏',
          content: {
            sub_title: '智能媒体屏',
            describe: '媒体屏连接物联网平台后，云上实时感知设备状态，媒体屏实时更新内容，实现媒体屏的智能精细化运营，起到降本增效的作用。<br/> A、云上可管理所有媒体屏，实现新媒体的智能化内容运营。<br/> B、企业服务实例可远程下发媒体内容，节省传统媒体屏人工维护成本。<br/> C、实例规格支持灵活扩展，能够支持业务的快速发展。',
            img: '/img/znmtp.png'
          }
        }
      ]
    }
  },
  methods: {
    handleLink(index, item) {
      this.currNav = index;
      console.log(item);
    },
  }
}
</script>

<style scoped>
.h {
  width: 30px;
  height: 3px;
  background-color: #373636;
  margin: 20px 0;
}
.solution {
  background-image: url(/img/solution_bg.jpg);
  background-size: cover;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-position: center;
}
.con {
  box-shadow: 0px 0px 10px #00000014;
}
.con .swiper-wrapper {
  background: #EDF1F7;
  border-radius: 6px 6px 0px 0px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.con .swiper-wrapper .swiper-slide {
  flex: 1;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.con .swiper-wrapper .swiper-slide span {
  height: 100%;
  line-height:74px;
  font-size: 17px;
}
.con .swiper-wrapper .swiper-slide-thumb-active span {
  border-bottom: 3px solid #2468F2;
  color:#2468F2;
  font-weight: bold;
}

.con .swiper-container {
  padding: 80px 90px;
  display: flex;
  border-radius: 0px 0px 6px 6px;
  background: #fff;
}
.swiper-slide {
  display: flex;
}
.swiper-slide .left {
  flex: 1;
  padding: 0 40px 0 0;
}
.swiper-slide .left h2 {
  font-weight: bold;
}
.swiper-slide .left p {
  margin-top: 30px;
}

.swiper-slide .right {
  flex: 1;
  padding: 0 0 0 40px;
}
.swiper-slide .right img {
  width: 100%;
  /* height: 100px; */
}
</style>