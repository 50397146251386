<template>
  <section id="" class="solution">
    <div class="container pt60 pb60">

      <div class="row">
        <div class="col-lg-12 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="w100 tc">
            <p class="title ft30 bold white">▶ 助力行业解决方案</p>
            <div class="mtb20 white" style="opacity: .5;">助力企业通过数字可视化更便捷的认知和管理</div>
          </div>
        </div>
      </div>

      <div class="con mt20">

        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in list"
            :key="index"
            :class="index == currNav ? 'swiper-slide-thumb-active' : ''"
            class="swiper-slide"
            @click="handleLink(index, item)">
            <span>{{ item.title }}</span>
          </div>
        </div>

        <div class="swiper-container">
          <div v-for="(item, index) in list" :key="index">
            <div v-if="index == currNav" class="swiper-slide">
              <div class="left animated flipInX">
                <h2>{{ item.content.sub_title }}</h2>
                <div class="h"></div>
                <p>{{ item.content.describe }}</p>
              </div>
              <div class="right animated flipInX">
                <img :src="item.content.img" alt="">
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      currNav: 0,
      list: [
        {
          title: '3D智慧工厂',
          content: {
            sub_title: '3D物联网智慧工厂',
            describe: '3D物联网智慧工厂是数字孪生技术重要的应用场景之一。打造以生产数据为核心、业务为纽带的数字系统，打造集工厂多项技术于一体的可视化平台。支持显示产品合格率、产量、销售额、生产成本等生产统计数据，方便管理者直观了解工厂的实时生产经营情况。',
            img: '/img/qjgk.jpg'
          }
        },
        {
          title: '3D智慧安防',
          content: {
            sub_title: '3D智慧安防解决方案',
            describe: '借助图像识别技术，集成视频监控、消防管理、环境监控等系统，提供告警处置联动机制，提升园区应急管理的反应速度。',
            img: '/img/zhaf.jpg'
          }
        },
        {
          title: '3D智慧能耗',
          content: {
            sub_title: '3D智慧能耗解决方案',
            describe: '根据实时能耗监控数据，在园区数字孪生场景中综合展示各项耗电情况，包括分类统计面板、分区统计面板和历史趋势图表等，使管理能够准确掌握能源成本比重和发展趋势。',
            img: '/img/zhnh.jpg'
          }
        },
        {
          title: '3D智慧医疗',
          content: {
            sub_title: '3D智慧医疗解决方案',
            describe: '智能医疗是通过移动监控、移动诊所、无线远程咨询、医疗信息云存储等智能技术手段，提高行业诊疗效率。提高城市诊疗覆盖面和效率，促进城市医疗资源的合理分配。',
            img: '/img/zhnh.jpg'
          }
        },
        {
          title: '3D数字能源可视化',
          content: {
            sub_title: '3D数字能源可视化解决方案',
            describe: '基于水利电力系统，展示城市电力设备、水资源、燃气管道的分布和方向。同时，实时监控和规划预测各种能源供，实现城市供水供电业务的三维可视化。',
            img: '/img/zhnh.jpg'
          }
        },
        {
          title: '数字档案馆',
          content: {
            sub_title: '数字档案馆解决方案',
            describe: '利用数字孪生技术建设数字档案馆，通过对接资产管理系统，支持校园资产详细信息的查看，大容量在线存储学生档案信息。通过数字列表或多条件搜索，可以快速定位空间位置，查看动态和静态数据。同时建立校园数字管理平台，实现教务可视化管理。通过与教务系统的对接，实时显示课堂安排状态、教学图片、远程听力、远程巡逻等信息。',
            img: '/img/zhnh.jpg'
          }
        },
        {
          title: '仓储物流可视化',
          content: {
            sub_title: '仓储物流可视化解决方案',
            describe: '仓储物流可视化系统基于3D可视化技术，通过数字孪生技术构建仓库和设备的逐级可视化。整合仓储、存储、仓储过程中的信息，构建数字化、标准化、智能化、集成化的可视化平台，实现视频监控、智能仓储、仓储操作、预测警告等可视化功能。为仓储物流提供强有力的科技支持，为管理部门提供准确的决策支持平台。',
            img: '/img/zhnh.jpg'
          }
        }
      ]
    }
  },
  methods: {
    handleLink(index, item) {
      this.currNav = index;
      console.log(item);
    },
  }
}
</script>

<style scoped>
.h {
  width: 30px;
  height: 3px;
  background-color: #373636;
  margin: 20px 0;
}
.solution {
  background-image: url(/img/solution_bg.jpg);
  background-size: cover;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-position: center;
}
.con {
  box-shadow: 0px 0px 10px #00000014;
}
.con .swiper-wrapper {
  background: #EDF1F7;
  border-radius: 6px 6px 0px 0px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.con .swiper-wrapper .swiper-slide {
  flex: 1;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.con .swiper-wrapper .swiper-slide span {
  height: 100%;
  line-height:74px;
  font-size: 17px;
}
.con .swiper-wrapper .swiper-slide-thumb-active span {
  border-bottom: 3px solid #2468F2;
  color:#2468F2;
  font-weight: bold;
}

.con .swiper-container {
  padding: 80px 90px;
  display: flex;
  border-radius: 0px 0px 6px 6px;
  background: #fff;
}
.swiper-slide {
  display: flex;
}
.swiper-slide .left {
  flex: 1;
  padding: 0 40px 0 0;
}
.swiper-slide .left h2 {
  font-weight: bold;
}
.swiper-slide .left p {
  margin-top: 30px;
}

.swiper-slide .right {
  flex: 1;
  padding: 0 0 0 40px;
}
.swiper-slide .right img {
  width: 100%;
  /* height: 100px; */
}
</style>