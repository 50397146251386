<template>
  <div class="colorlib-intro">
    <div class="container wow flipInY">
      <div class="row">
        <div class="col-md-12 center-title">
          <h2 class="ft30 bold">- 企业简介 -</h2>
          <div class="mtb20">COMPANY PROFILE</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="plr70 tc ft20">山东惠云软件科技有限公司成立于2021年4月7日，主要业务涵盖软件开发、技术服务、行业解决方案等，业务覆盖电子政务、教育、金融、医疗等行业。公司具备完善的自主研发体系，提供系统集成、物联网、数字孪生等技术服务，通过多种形式研发创新，不断探索前沿技术的应用潜力，提升产品质量，为客户提供更好的信息技术服务。</p>
        </div>
        <div class="col-md-12 ptb40">
          <div class="Mbtn on"><div class="navbtn"><span><i class="fa fa-play" aria-hidden="true"></i></span></div></div>
        </div>
        <!-- <div class="col-md-12 pb40">
          <div class="w80 plr20 ptb20 shadow2 radius10 mauto overxy">
            <img class="w100" src="/img/gszz.jpg" alt="img" style="display: block;">
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>

.colorlib-intro {
  background: whitesmoke;
  clear: both; 
}

.Mbtn .navbtn{ 
  width: 80px; height: 80px; 
  line-height: 80px; text-align: center; 
  border-radius: 50%; border-radius: 50%; 
  position: relative; z-index: 2; 
  margin: 0 auto;
  cursor: pointer;
}
.Mbtn .navbtn:after{
  content:""; opacity:0;
  width:70px; height:70px; display:block;
  position:absolute; left:50%; top:50%;  z-index:1;
  margin-left:-35px; margin-top:-35px;
  background:#798eea;
  -moz-border-radius:50%;-webkit-border-radius:50%;border-radius:50%;
  animation:scaleAnim1 1.6s infinite linear;
}
.Mbtn .navbtn:before{
  content:""; opacity:0;
  width:80px; height:80px;  display:block;
  position:absolute; left:50%; top:50%; z-index:1;
  margin-left:-40px; margin-top:-40px;
  background:#98aaf7;
  -moz-border-radius:50%;-webkit-border-radius:50%;border-radius:50%;
  animation:scaleAnim2 1.6s infinite linear;
}
.Mbtn .navbtn:before{animation-delay:-.5s}

.Mbtn .navbtn span{ 
  width: 60px; height: 60px; display: block;
  line-height: 60px; font-size: 14px; color: #fff;
  position: absolute; left:50%; top:50%; z-index: 4;
  margin-left: -30px; margin-top: -30px;
}
.Mbtn .navbtn span:before{
  content:"";
  width:40px; height:40px; display:block;
  position:absolute; left:50%; top:50%; 
  border-radius: 50%;
  margin-left: -20px; margin-top: -20px; 
  /* background: #e1e1e1; */
}
.Mbtn .navbtn span:after{
  content:"";
  width:30px; height:30px; display:block;
  position:absolute; left:50%; top:50%;
  border-radius: 50%; 
  margin-left:-15px; margin-top: -15px; 
  line-height: 30px; 
  /* background: #fff; */
  color: #fff 
}

/* .Mbtn:hover .navbtn span:after{background: #079399;color: #fff;}
.Mbtn:hover .navbtn span:before{background: #2ca19d;} */

@keyframes scaleAnim2{
  0%{transform:scale(.3);opacity:0}
  50%{transform:scale(.7);opacity:.4}
  100%{transform:scale(1.4);opacity:0}
}
@keyframes scaleAnim1{
  0%{transform:scale(.3);opacity:0}
  50%{transform:scale(.6);opacity:.8}
  100%{transform:scale(1.6);opacity:0}
}

.center-title {
  text-align: center;
  padding: 60px 0 20px 0;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 20px;
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  /* line-height: 28px; */
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}
</style>