<template>
  <section id="projectManage" class="ws-section-spacing about-bg">
    <div class="container">
      <div class="row about-content">
        <div class="col-lg-6 col-md-12 flex alcenter jscenter wow bounceInLeft flex jscenter alcenter">
          <img class="w75" src="/img/service-img2.png" alt="img">
        </div>
        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="wt500">
            <div class="left-title">
              <p class="title ft30 bold">▶ 政务服务 </p>
              <h4 class="sub-title">面向政府部门</h4>
            </div>
            <div>计算机技术、互联网技术、物联网技术、AI技术等已经渗透到政府、企事业单位的日常工作中，大量的公文、报告、数据等各类信息量越来越大，涉及到的部门、业务关联人越来越广泛。传统的线下处理方式以及单一的管理平台已经不能满足现阶段发展的需要，已经影响了领导的决策和业务的发展，迫切需要利用已有的可利用资源来实现信息化综合管理，加快内部的信息流通、信息共享以及信息的有效利用，有效提高办公效率。</div>
            <!-- <ul>
              <li class="about-point"> <span class="dot"></span>Ded do eiusmod tempor incididunt ut labore.
              </li>
              <li class="about-point"> <span class="dot"></span>Ded do eiusmod tempor incididunt ut labore.
              </li>
              <li class="about-point"> <span class="dot"></span>Ded do eiusmod tempor incididunt ut labore.
              </li>
              <li class="about-point"> <span class="dot"></span>Ded do eiusmod tempor incididunt ut labore.
              </li>
            </ul> -->
            <a href="javascript:;" class="btn" @click="$router.push({ path: '/government' })">查看详情 >></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  
}
</script>
<style scoped>
/* ----------------------------------------------------------------
[ 06 About-section ]
-----------------------------------------------------------------*/
h2 {
    font-size: 33px;
    line-height: 48px;
    margin-bottom: 0px;
    font-weight: 700;
}

.about-bg {
  /* background-image: url('~@/assets/about-bg.png');
  background-repeat: no-repeat; */
  padding: 90px 0 45px 0;
}


.left-title {
  text-align: left;
  padding-bottom: 30px;
}

.left-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.left-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  font-weight: 600;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  border: 2px solid #596dee;
  display: inline-block;
  margin-right: 10px;
}

.about-point {
  font-weight: 600;
  padding-bottom: 15px;
}

.btn {
  border-radius: 8px;
  background-color: #ff8a41;
  margin: 40px 0 30px 0;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 12px 40px;
  width: 50%;
}

.btn:hover {
  background-color: #596dee;
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

/* ----------------------------------------------------------------
     [ End About-section ]
-----------------------------------------------------------------*/
</style>