<template>
  <section id="yrjy" class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 幼儿教育方案功能介绍 -</h2>
            <h4 class="sub-title">Introduction to the functions of early childhood education program</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="(item, index) in list" :key="index" class="col-lg-4 col-md-6">
          <div class="service-box wow flipInX">
            <div class="service-icon">
              <span class="service-icon-bg"><i class="fa" :class="item.icon" aria-hidden="true"></i></span>
            </div>
            <div class="service-content">
              <h4 class="mb10 bold ft18">{{ item.title  }}</h4>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '智慧安全',
          content: '幼儿入园、幼儿园内位置、幼儿园内活动轨迹、幼儿离园',
          icon: 'fa-cubes'
        },
        {
          title: '智慧教学',
          content: '教学文档管理、工作计划、工作总结、教学活动、资源库管理、日程管理、日常值班、图书管理',
          icon: 'fa-address-book'
        },
        {
          title: '智慧卫生',
          content: '体检管理、健康状况分析、健康状况跟踪、健康指导、健康对比',
          icon: 'fa-id-card'
        },
        {
          title: '智慧营养',
          content: '膳食原料库、菜肴库、带量食谱库、营养标准、营养分析; 食材入库、食材出库、食材库存查询、食材快检、食堂行政物品管理',
          icon: 'fa-balance-scale'
        },
        {
          title: '智慧财务',
          content: '收费标准、收扣退费、批量收费、收费分析、选修课收费、工资管理、支出审批、经营分析',
          icon: 'fa-money'
        },
        {
          title: '家园共育',
          content: '通过微信小程序实现家校信息互通，促进家园共育工作，家长端小程序可实现功能',
          icon: 'fa-mortar-board'
        },
        {
          title: '智慧后勤',
          content: '类别管理、物品管理、用品管理、食堂采购、固定资产、采购计划、入库、库存、出库、借还、数据导入、统计分析',
          icon: 'fa-recycle'
        },
        {
          title: '智慧教务',
          content: '幼儿管理、教职工管理、班级管理、课程管理、教室管理、园所信息、考勤统计',
          icon: 'fa-sitemap'
        },
        {
          title: '智慧OA',
          content: '通知公告、工作任务、工作日志、办公审批、工作计划',
          icon: 'fa-server'
        }
      ]
    }
  }
}
</script>

<style scoped>

.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 05 Service-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding-top: 80px;
  /* padding-bottom: 80px; */
}

.service-icon span.service-icon-bg i:before {
  font-size: 30px;
}

.service-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 24px 22px 0px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 22px 0px rgba(40, 44, 71, 0.12);
}

.service-icon {
  width: 30%;
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.icon {
  width: 86px;
  height: 66px;
  background-color: #ff8a41;
}

.service-content {
  width: 68%;
  margin-left: auto;
}
.service-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}

.service-icon-bg {
  background-color: #ff8a41;
  width: 86px;
  height: 66px;
  padding-left: 15px;
  display: block;
  font-size: 33px;
  text-align: center;
  line-height: 2;
  color: #fff;
  border-radius: 0 8px 8px 0;
}

</style>
