<template>
  <section id="school" class="ws-section-spacing about-bg">
    <div class="container">
      <div class="row about-content">
        <div class="col-lg-6 col-md-12 wow bounceInLeft">
          <div class="ht300 bb_shadow radius10 tc w90 mauto">
            <img class="h100 w100" src="/img/zhaf.jpg" alt="img">
          </div>
        </div>
        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="w90 mauto">
            <div class="left-title">
              <p class="title ft30 bold">▶ 更全面的分析和预测能力</p>
              <!-- <h4 class="sub-title">应用场景：校园、工厂、农业、园区、医院、城市、公安</h4> -->
            </div>
            <div>
              数字孪生技术可以通过物联网的数据采集和大数据处理来分析和诊断当前的状态，推断未来的发展趋势，为未来的决策提供有价值的参考数据。
            </div>
            <!-- <ul>
              <li class="about-point"> <span class="dot"></span>一键打通数据孤岛
              </li>
            </ul> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  
}
</script>
<style scoped>
/* ----------------------------------------------------------------
[ 06 About-section ]
-----------------------------------------------------------------*/
h2 {
    font-size: 33px;
    line-height: 48px;
    margin-bottom: 0px;
    font-weight: 700;
}

.about-bg {
  /* background-image: url('~@/assets/about-bg.png');
  background-repeat: no-repeat; */
  padding: 90px 0 45px 0;
}


.left-title {
  text-align: left;
  padding-bottom: 30px;
}

.left-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.left-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  font-weight: 600;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  border: 2px solid #596dee;
  display: inline-block;
  margin-right: 10px;
}

.about-point {
  font-weight: 600;
  padding-bottom: 15px;
}

.btn {
  border-radius: 8px;
  background-color: #ff8a41;
  margin: 40px 0 30px 0;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 12px 40px;
  width: 50%;
}

.btn:hover {
  background-color: #596dee;
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

/* ----------------------------------------------------------------
     [ End About-section ]
-----------------------------------------------------------------*/
</style>