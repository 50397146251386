<template>
  <div>
    <Section />
    <Sidebar />
    <Modular4 />
    <Modular3 />
    
    <!-- S 党建解决方案 -->
    <Modular2 />
    <!-- E 党建解决方案 -->

    <!-- S 政务办公解决方案 -->
    <Modular5 />
    <!-- E 政务办公解决方案 -->

    <!-- S 教育局解决方案 -->
    <Modular6 />
    <!-- E 教育局解决方案 -->

    <!-- S 农业环境监测解决方案 -->
    <Modular7 />
    <!-- E 农业环境监测解决方案 -->

    <!-- S 财务预决算解决方案 -->
    <Modular8 />
    <!-- E 财务预决算解决方案 -->

    <!-- S 警务系统解决方案 -->
    <Modular9 />
    <!-- E 警务系统解决方案 -->

    <!-- S 相关案例 -->
    <Modular1 />
    <!-- E 相关案例 -->

  </div>
</template>

<script>
import Section from './components/section/Section.vue'
import Sidebar from './components/sidebar/Sidebar.vue'
import Modular1 from './components/modular1/index.vue'
import Modular2 from './components/modular2/index.vue'
import Modular3 from './components/modular3/index.vue'
import Modular4 from './components/modular4/index.vue'
import Modular5 from './components/modular5/index.vue'
import Modular6 from './components/modular6/index.vue'
import Modular7 from './components/modular7/index.vue'
import Modular8 from './components/modular8/index.vue'
import Modular9 from './components/modular9/index.vue'

export default {
  components: {
    Section,
    Sidebar,
    Modular1,
    Modular2,
    Modular3,
    Modular4,
    Modular5,
    Modular6,
    Modular7,
    Modular8,
    Modular9
  },
  mounted() { 
    new this.$wow.WOW().init()
  }
}
</script>

<style scoped>

</style>
