<template>
  <section id="zxxjy" class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 功能概览 -</h2>
            <!-- <h4 class="sub-title">Introduction to the functions of primary and secondary education programs</h4> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="(item, index) in list" :key="index" class="col-lg-6 col-md-6">
          <div class="service-box wow flipInX">
            <div class="service-icon">
              <span class="service-icon-bg"><i class="fa" :class="item.icon"></i></span>
            </div>
            <div class="service-content">
              <h4 class="mb10 bold ft18">{{ item.title  }}</h4>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '实例管理',
          content: '面向物联网个人开发者和企业客户提供试用公共实例，开通物联网平台服务即可体验物联网平台功能；面向企业客户提供物联网平台实例，支持设备接入、管理运维、数据服务等功能',
          icon: 'fa-sticky-note'
        },
        {
          title: '设备接入',
          content: '支持多协议（MQTT、CoAP、HTTPS）、多平台（C、Node.js、Java等）、多网络（2G/3G/4G/5G、NB-IoT、LoRaWAN、Wi-Fi等）、多地域设备快速接入，缩短物联网设备上云的研发周期。',
          icon: 'fa-users'
        },
        {
          title: '设备管理',
          content: '提供高可靠高安全的设备管理能力。例如设备生命周期管理、高级搜索、标签、设备任务、设备影子、设备文件、数字孪生等服务。',
          icon: 'fa-pencil-square'
        },
        {
          title: '规则引擎',
          content: '与阿里云众多云产品（Table Store、RDS、Lindorm、TSDB、FC、MNS、RocketMQ等）打通，满足不同场景下的业务需求。例如M2M通信、设备数据存储、设备数据计算、消息队列服务等。',
          icon: 'fa-wrench'
        },
        {
          title: '监控运维',
          content: '提供实例级监控报警、运维大盘、全链路日志分析、设备OTA升级、远程登录、设备智能诊断和安全中心服务。',
          icon: 'fa-cubes'
        },
        {
          title: '增值服务',
          content: '为不同类型的智能设备提供千里传音、寄雁传书、音视频通信、位置服务、声码服务、事件响应、数据智能等丰富的增值服务。您可选购和开通增值服务，丰富设备业务。',
          icon: 'fa-window-restore'
        }
      ]
    }
  }
}
</script>

<style scoped>

.center-title {
  text-align: center;
  /* padding-bottom: 60px; */
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 05 Service-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding-top: 80px;
  padding-bottom: 40px;
}

.service-icon span.service-icon-bg i:before {
  font-size: 30px;
}

.service-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 24px 22px 0px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 22px 0px rgba(40, 44, 71, 0.12);
}

.service-icon {
  width: 20%;
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.icon {
  width: 86px;
  height: 66px;
  background-color: #ff8a41;
}

.service-content {
  width: 78%;
  margin-left: auto;
}
.service-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}

.service-icon-bg {
  background-color: #71c44b;
  width: 86px;
  height: 66px;
  padding-left: 15px;
  display: block;
  font-size: 33px;
  text-align: center;
  line-height: 2;
  color: #fff;
  border-radius: 0 8px 8px 0;
}

</style>
