<template>
  <section id="" class="ws-section-spacing about-bg bginput">
    <div class="container">
      <h3 class="tc mb30 bold mb60">- 专为企业级系统设计 -</h3>
      <div class="row about-content">
        <div class="col-lg-4 col-md-12 flex wow bounceInRight mb60">
          <i class="fa fa-paper-plane-o"></i>
          <div class="ml30">
            <p class="title">丰富字段类型</p>
            <p class="content">文本、数值、日期、表格，数十种字段类型按需搭配</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 flex wow bounceInRight">
          <i class="fa fa-newspaper-o"></i>
          <div class="ml30">
            <p class="title">自定义布局样式</p>
            <p class="content">最高支持4栏布局背景表头样式自定义</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 flex wow bounceInRight">
          <i class="fa fa-object-group"></i>
          <div class="ml30">
            <p class="title">跨应用数据关联</p>
            <p class="content">一键关联其他应用字段筛选、聚合操作辅助灵活填写</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 flex wow bounceInRight">
          <i class="fa fa-random"></i>
          <div class="ml30">
            <p class="title">公式函数计算</p>
            <p class="content">逻辑/数字/时间函数，数据处理有如神助</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 flex wow bounceInRight">
          <i class="fa fa-superpowers"></i>
          <div class="ml30">
            <p class="title">支持外链填写</p>
            <p class="content">获得链接即可填写随时随地发起申请</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 flex wow bounceInRight">
          <i class="fa fa-free-code-camp"></i>
          <div class="ml30">
            <p class="title">表单中嵌套子表单</p>
            <p class="content">设计表格字段后，一个表单即可批量收集信息，还可以通过规则预设确保信息准确性</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  
}
</script>
<style scoped>
/* ----------------------------------------------------------------
[ 06 About-section ]
-----------------------------------------------------------------*/
h2 {
    font-size: 33px;
    line-height: 48px;
    margin-bottom: 0px;
    font-weight: 700;
}

.about-content {
  padding: 60px 40px;
  box-shadow: 0px 0px 20px rgb(22 158 254 / 14%);
  background: #fff;
}
.about-content .flex:hover i {
  color: #d6175a;
  transform: rotateZ(360deg);
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

.about-content i {
  color: #4183df;
  font-size: 26px;
  margin-top: 6px;
}

.about-content .title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.about-bg {
  padding: 60px 0 60px 0;
}


.left-title {
  text-align: left;
}

.left-title .title {
  text-transform: capitalize;
}

.left-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  font-weight: 600;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  border: 2px solid #1559b7;
  display: inline-block;
  margin-right: 10px;
}

.about-point {
  font-weight: 600;
  padding-bottom: 15px;
}

.btn {
  border-radius: 8px;
  background-color: #ff8a41;
  margin: 40px 0 30px 0;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 12px 40px;
  width: 50%;
}

.btn:hover {
  background-color: #596dee;
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

/* ----------------------------------------------------------------
     [ End About-section ]
-----------------------------------------------------------------*/
</style>