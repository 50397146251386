<template>
  <div class="colorlib-intro">
    <div class="container wow flipInY">
      <div class="row">
        <div class="col-md-12 center-title">
          <h2 class="title">- 中小学教育方案介绍 -</h2>
          <h4 class="sub-title">Introduction to primary and secondary education programs</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="plr70 tc">智慧校园从用户角度出发，打破传统业务系统边界，建设一个扁平化、易操作、搜索引擎式的统一开放可扩展的信息和服务平台。平台承载高校各类业务和信息，实现数据资产的可视化呈现、统计分析和辅助决策，培育高校教学、科研、管理和生活的线上智慧型社区生态。平台包括网上事务中心、大数据中心、网络社区平台、资讯中心、个人工作台、移动门户等方面内容。</p>
        </div>
        <div class="col-md-12 mt40">
          <div class="Mbtn on"><div class="navbtn"><span><i class="fa fa-play" aria-hidden="true"></i></span></div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>

.colorlib-intro {
  background: whitesmoke;
  padding: 60px 0;
  clear: both; 
}
.colorlib-intro .container .box{
  text-align: center;
  padding: 0 200px;
}
.colorlib-intro .container .box h2 {
  font-size: 33px; font-weight: 700; color: #303133;
  font-family: "Nunito", Arial, sans-serif;
  margin: 0 0 20px 0;
  line-height: 1.3;
}
.colorlib-intro .container .box p {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
  color: gray;
  margin-bottom: 80px;
}

.Mbtn .navbtn{ 
  width: 80px; height: 80px; 
  line-height: 80px; text-align: center; 
  border-radius: 50%; border-radius: 50%; 
  position: relative; z-index: 2; 
  margin: 0 auto;
  cursor: pointer;
}
.Mbtn .navbtn:after{
  content:""; opacity:0;
  width:70px; height:70px; display:block;
  position:absolute; left:50%; top:50%;  z-index:1;
  margin-left:-35px; margin-top:-35px;
  background:#798eea;
  -moz-border-radius:50%;-webkit-border-radius:50%;border-radius:50%;
  animation:scaleAnim1 1.6s infinite linear;
}
.Mbtn .navbtn:before{
  content:""; opacity:0;
  width:80px; height:80px;  display:block;
  position:absolute; left:50%; top:50%; z-index:1;
  margin-left:-40px; margin-top:-40px;
  background:#98aaf7;
  -moz-border-radius:50%;-webkit-border-radius:50%;border-radius:50%;
  animation:scaleAnim2 1.6s infinite linear;
}
.Mbtn .navbtn:before{animation-delay:-.5s}

.Mbtn .navbtn span{ 
  width: 60px; height: 60px; display: block;
  line-height: 60px; font-size: 14px; color: #fff;
  position: absolute; left:50%; top:50%; z-index: 4;
  margin-left: -30px; margin-top: -30px;
}
.Mbtn .navbtn span:before{
  content:"";
  width:40px; height:40px; display:block;
  position:absolute; left:50%; top:50%; 
  border-radius: 50%;
  margin-left: -20px; margin-top: -20px; 
  /* background: #e1e1e1; */
}
.Mbtn .navbtn span:after{
  content:"";
  width:30px; height:30px; display:block;
  position:absolute; left:50%; top:50%;
  border-radius: 50%; 
  margin-left:-15px; margin-top: -15px; 
  line-height: 30px; 
  /* background: #fff; */
  color: #fff 
}

/* .Mbtn:hover .navbtn span:after{background: #079399;color: #fff;}
.Mbtn:hover .navbtn span:before{background: #2ca19d;} */

@keyframes scaleAnim2{
  0%{transform:scale(.3);opacity:0}
  50%{transform:scale(.7);opacity:.4}
  100%{transform:scale(1.4);opacity:0}
}
@keyframes scaleAnim1{
  0%{transform:scale(.3);opacity:0}
  50%{transform:scale(.6);opacity:.8}
  100%{transform:scale(1.6);opacity:0}
}

.center-title {
  text-align: center;
  padding-bottom: 40px;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 20px;
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  /* line-height: 28px; */
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

</style>