<template>
  <div>
    <Section />

    <Modular0 />

    <Modular7 />
    
    <Modular1 />

    <Modular4 />

    <Modular2 />

    <Modular5 />

    <Modular3 />

    <Modular6 />
  </div>
</template>

<script>
import Section from './components/section/Section.vue'
import Modular0 from './components/modular0/index.vue'
import Modular1 from './components/modular1/index.vue'
import Modular2 from './components/modular2/index.vue'
import Modular3 from './components/modular3/index.vue'
import Modular4 from './components/modular4/index.vue'
import Modular5 from './components/modular5/index.vue'
import Modular6 from './components/modular6/index.vue'
import Modular7 from './components/modular7/index.vue'

export default {
  name: 'technicalProposalIndex',
  components: {
    Section,
    Modular0,
    Modular1,
    Modular2,
    Modular3,
    Modular4,
    Modular5,
    Modular6,
    Modular7
  }
}
</script>

<style scoped>
</style>
