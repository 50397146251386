<template>
  <section id="zwfa" class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 政务办公方案介绍 -</h2>
            <h4 class="sub-title">Introduction to government office solutions</h4>
          </div>
        </div>
      </div>
      <div class="row">

        <div v-for="(item, index) in list" :key="index" class="col-lg-4 col-md-6">
          <div class="service-box wow flipInX">
            <div class="service-icon">
              <span class="service-icon-bg">
                <i class="fa" :class="item.icon" aria-hidden="true"></i>
              </span>
            </div>
            <div class="service-content">
              <h4 class="mb10 bold ft18">{{ item.title  }}</h4>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '行政审批',
          content: '请假审批、出差审批、采购审批、报销审批',
          icon: 'fa-address-book'
        },
        {
          title: '智慧OA',
          content: '通知公告、工作任务、工作日志、办公审批、工作计划',
          icon: 'fa-desktop'
        },
        {
          title: '智慧后勤',
          content: '类别管理、物品管理、用品管理、日常采购、固定资产、采购计划、入库、库存、出库、借还、数据导入、统计分析',
          icon: 'fa-cube'
        },
        {
          title: '智慧财务',
          content: '财政收入管理、工资管理、支出审批、经营分析',
          icon: 'fa-fax'
        },
        {
          title: '信息管理',
          content: '政务新闻、通知公告、规章制度、学习活动、检查通报',
          icon: 'fa-home'
        },
        {
          title: '督查督办',
          content: '重点项目督查、日常督查、领导批示督查、专项督查、督查考核、督查监控、公示及通报',
          icon: 'fa-mail-reply-all'
        },
        {
          title: '公文管理',
          content: '发文管理、收文管理、文件登记、签收查询、办理意见、领导批示、手写签批、办理反馈、公文传阅、查询统计、图表统计、电子签章、多人会签、权限管理、公文交换、公文清稿',
          icon: 'fa-home'
        },
        {
          title: '移动办公',
          content: '电子邮件、即时通讯、任务协同、日程安排、工作汇报、手机考勤、请假打卡、移动审批、数据填报、决策分析',
          icon: 'fa-home'
        },
        {
          title: '人事管理',
          content: '人员管理、岗位规划、考勤管理、薪资管理、保险福利、合同管理、报表统计、规章制度、组织架构管理',
          icon: 'fa-home'
        },
      ]
    }
  }
}
</script>

<style scoped>

.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 05 Service-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding-top: 80px;
  /* padding-bottom: 80px; */
}

.service-icon span.service-icon-bg i:before {
  font-size: 30px;
}

.service-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 24px 22px 0px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 22px 0px rgba(40, 44, 71, 0.12);
}

.service-icon {
  width: 30%;
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.icon {
  width: 86px;
  height: 66px;
  background-color: #ff8a41;
}

.service-content {
  width: 68%;
  margin-left: auto;
}
.service-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}

.service-icon-bg {
  background-color: #71c44b;
  width: 86px;
  height: 66px;
  padding-left: 15px;
  display: block;
  font-size: 33px;
  text-align: center;
  line-height: 2;
  color: #fff;
  border-radius: 0 8px 8px 0;
}

</style>
