<template>
  <section id="csfa" class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 财务预决算解决方案 -</h2>
            <h4 class="sub-title">Financial budget and final settlement solution</h4>
          </div>
        </div>
      </div>
      <div class="row">

        <div v-for="(item, index) in list" :key="index" class="col-lg-4 col-md-6">
          <div class="service-box wow flipInX">
            <div class="service-icon">
              <span class="service-icon-bg">
                <i class="fa" :class="item.icon" aria-hidden="true"></i>
              </span>
            </div>
            <div class="service-content">
              <h4 class="mb10 bold ft18">{{ item.title  }}</h4>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '智能核算',
          content: '合同计费、流程审核、自动结算、RPA自动对账',
          icon: 'fa-address-book'
        },
        {
          title: '智能费控',
          content: '报销申请、支出审批、支出统计、支出数据分析、资金稽核、流水核验、数据预警',
          icon: 'fa-desktop'
        },
        {
          title: '智能财资',
          content: '多账户管理、对账催缴、账务汇总、单据汇总、账务信息、合并报销、一键生成报表',
          icon: 'fa-cube'
        },
        {
          title: '智能分析',
          content: '现金预算、预计利润、预计负债、预计现金流量表、收支分析、数据预警、数据汇总',
          icon: 'fa-fax'
        },
        {
          title: '智慧OA',
          content: '通知公告、工作任务、工作日志、办公审批、工作计划',
          icon: 'fa-home'
        },
        {
          title: '公文管理',
          content: '发文管理、收文管理、文件登记、签收查询、办理意见、领导批示、手写签批、办理反馈、公文传阅、查询统计、图表统计、电子签章、多人会签、权限管理、公文交换、公文清稿',
          icon: 'fa-mail-reply-all'
        },
      ]
    }
  }
}
</script>

<style scoped>

.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 05 Service-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding-top: 80px;
  /* padding-bottom: 80px; */
}

.service-icon span.service-icon-bg i:before {
  font-size: 30px;
}

.service-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 24px 22px 0px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 22px 0px rgba(40, 44, 71, 0.12);
}

.service-icon {
  width: 30%;
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.icon {
  width: 86px;
  height: 66px;
  background-color: #e298d3;
}

.service-content {
  width: 68%;
  margin-left: auto;
}
.service-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}

.service-icon-bg {
  background-color: #e298d3;
  width: 86px;
  height: 66px;
  padding-left: 15px;
  display: block;
  font-size: 33px;
  text-align: center;
  line-height: 2;
  color: #fff;
  border-radius: 0 8px 8px 0;
}

</style>
