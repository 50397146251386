<template>
  <section class="hero-header primary-header slider-header" id="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="hero-header-content">
              <p class="ft30 bold"> - 医疗行业 - </p>
              <p>随着各信息化技术的快速发展，以及云计算、移动化、大数据、物联网等新技术的出现和发展，医疗业务与互联网对接已是不可避免的趋势。特别在2015年3月5日十二届全国人大三次会议上，李克强总理在政府工作报告中首次提出“互联网+”行动计划。进一步要求了各个业务与互联网的深度融合，医疗行业在利用互联网、移动化技术实现医生随访、移动护理、自助交费、院外康复和家庭病床等业务也利用新技术实现了高速的发展。</p>
              <div class="button">
                <a href="javascript:;" class="btn primary video-popup mfp-iframe">观看视频</a>
                <a href="#" class="btn btn-secondary">去了解 >></a>
              </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="hero-header-image">
              <img src="@/assets/hero-image.svg" alt="#">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {
    
  }
}
</script>

<style scoped>
/*======================================
    Start Hero Area CSS
========================================*/  
.hero-header {
	min-height: 40vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-size: contain;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-color: #fff;
	position:relative;
  background-color: #597fdd;
	/* background: linear-gradient(45deg,#006dfe,#1407b3); */
}
.hero-header-content {
  height: 100%;
	display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0 0 0;
}
.hero-grid {
	display: grid;
	grid-template-columns: 40% 60%;
	align-items: center;
}
.hero-header-content p {
	text-align: left;
	color:#fff;
	margin-top:20px;
}
.hero-header h1 {
	font-style: normal;
	font-weight: 300;
	font-size: 48px;
	line-height: 55px;
	text-align: left;
	color: #fff;
}
.hero-header .button{
	margin-top:20px;
}
.hero-header .btn {
	text-align: left;
	float: left;
	background: #fff;
	color: #333;
	border: none;
	box-shadow: none;
	border: 1px solid #fff;
	background: transparent;
	color: #fff !important;
	margin-right:10px;
}
.hero-header .btn i{
	font-size:13px;
	display:inline-block;
	margin-left:4px;
}
.hero-header .btn:last-child{
	margin:0;
}
.hero-header .btn:hover{
	background:#fff;
	color:#006DFE !important;
	border-color:transparent;
}
.hero-header .btn.primary{
	background:#fff;
	color:#006DFE !important;
	border-color:transparent;
}
.hero-header .btn:before{
	display:none;
}
.hero-header .hero-header-image{
	margin-top:80px;
}
.hero-header input {
	display: inline-block;
	width: 240px;
	height: 50px;
	border: none;
	background: #fff;
	color: #333;
	border-radius: 3px;
	margin-right: 10px;
	font-size: 14px;
	padding: 0px 20px;
	font-weight: 400;
}
.hero-header .input-form{
	margin-top:30px;
}
.hero-header .input-group-append{
	display:inline-block;
}
.hero-header .input-group-append .app-btn{
	width: 120px;
	height: 50px;
	border: none;
	background: #fff;
	color: #333;
	border-radius: 3px;
	margin-right: 10px;
	font-size: 14px;
	padding: 0px 20px;
	font-weight: 400;
	border:1px solid transparent;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.hero-header .input-group-append .app-btn:hover{
	background:transparent;
	border-color:#fff;
	color:#fff;
}
.home-v3{
	text-align: center;
}
.home-v3 .hero-header-content{
	text-align: center;
	padding: 124px 100px;
}
.home-v3 .hero-header-content p {
	text-align: center;
}
.home-v3 .hero-header-content h1 {
	text-align: center;
}
.home-v3 .button {
	margin-top: 30px;
	text-align: center;
	display:inline-block;
}
/* Animation */
.home-v3 .owl-item.active .single-slider h1{
    animation: bounceIn 1s both 1s;
}
.home-v3 .owl-item.active .single-slider p {
    animation: fadeIn 0.7s both 1.3s;
}
.home-v3 .owl-item.active .single-slider .btn{
    animation: fadeIn 0.9s both 1.8s;
}
/* Hero Area 4 */
.home-v3 .owl-carousel .owl-nav {
	margin: 0;
    position: absolute;
    top: 50%;
    width: 100%;
	margin-top:-23px;
}
.home-v3 .owl-carousel .owl-nav div {
	height: 46px;
	width: 46px;
	line-height: 46px;
	text-align: center;
	background: #fff;
	font-size: 16px;
	position: absolute;
	margin: 0;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	padding: 0;
	border-radius: 0;
	background: transparent;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 100%;
}
.home-v3 .owl-carousel .owl-nav div:hover{
	background:#fff;
	color:#006DFE;
	border-color:transparent;
}
.home-v3 .owl-carousel .owl-controls .owl-nav .owl-prev{
	left:20px;
}
.home-v3 .owl-carousel .owl-controls .owl-nav .owl-next{
	right:20px;
}
/*======================================
   End Hero Area CSS
========================================*/ 


</style>
