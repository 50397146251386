<template>
  <div>
    <Section />
    <Modular8 />
    <Modular9 />

    <!-- S 幼儿教育方案 -->
    <!-- <Modular3 /> -->
    <Modular2 />
    <!-- E 幼儿教育方案 -->

    <!-- S 中小学教育方案 -->
    <!-- <Modular4 /> -->
    <Modular5 />
    <!-- E 中小学教育方案 -->

    <!-- S 高校教育方案 -->
    <!-- <Modular6 /> -->
    <Modular7 />
    <!-- E 高校教育方案 -->

    <Modular1 />
  </div>
</template>

<script>
import Section from './components/section/Section.vue'
import Modular1 from './components/modular1/index.vue'
import Modular2 from './components/modular2/index.vue'
import Modular3 from './components/modular3/index.vue'
import Modular4 from './components/modular4/index.vue'
import Modular5 from './components/modular5/index.vue'
import Modular6 from './components/modular6/index.vue'
import Modular7 from './components/modular7/index.vue'
import Modular8 from './components/modular8/index.vue'
import Modular9 from './components/modular9/index.vue'

export default {
  name: 'education',
  components: {
    Section,
    Modular1,
    Modular2,
    Modular3,
    Modular4,
    Modular5,
    Modular6,
    Modular7,
    Modular8,
    Modular9
  },
  mounted() { 
    new this.$wow.WOW().init()
  }
}
</script>

<style scoped>

</style>
