<template>
  <section id="school" class="ws-section-spacing about-bg bginput">
    <div class="container">
      <div class="row about-content">
        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="w90 mauto">
            <div class="left-title mb20">
              <p class="title ft18 bold">▶ 流程的灵活性体现</p>
            </div>
            <ul>
              <li class="about-point"> 
                <span class="dot"></span>串并行会签、同步、驳回、冻结、分支、版本、自由跳转、流程版本流程泳道、触发规则、催办、代理等
              </li>
              <li class="about-point"> 
                <span class="dot"></span>审批详情页面，根据流程节点配置，动态初始化流程 相关操作和权限，开发者无需关心
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInLeft">
          <div class="w90 mauto tc">
            <img class="h100 w100" src="/img/gzl_c3.png" alt="img">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  
}
</script>
<style scoped>
/* ----------------------------------------------------------------
[ 06 About-section ]
-----------------------------------------------------------------*/
h2 {
    font-size: 33px;
    line-height: 48px;
    margin-bottom: 0px;
    font-weight: 700;
}

.about-bg {
  /* background-image: url('~@/assets/about-bg.png');
  background-repeat: no-repeat; */
  padding: 90px 0 45px 0;
}


.left-title {
  text-align: left;
}

.left-title .title {
  text-transform: capitalize;
}

.left-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  font-weight: 600;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  border: 2px solid #1559b7;
  display: inline-block;
  margin-right: 10px;
}

.about-point {
  font-weight: 600;
  padding-bottom: 15px;
}

.btn {
  border-radius: 8px;
  background-color: #ff8a41;
  margin: 40px 0 30px 0;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 12px 40px;
  width: 50%;
}

.btn:hover {
  background-color: #596dee;
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

/* ----------------------------------------------------------------
     [ End About-section ]
-----------------------------------------------------------------*/
</style>