<template>
  <section id="school" class="ws-section-spacing about-bg">
    <div class="container">
      <div class="row about-content">
        <div class="col-lg-12 col-md-12 mb80">
          <h3 class="tc mb30 bold">- 多维度分析数随心动 -</h3>
          <p class="tc">面向每一个员工，自主灵活的对业务数据进行多维度分析，及时发现问题，快速决策</p>
        </div>
        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInLeft">
          <div class="w100 mauto tc">
            <img class="h100 w100" src="/img/bbsj_c3.png" alt="img">
          </div>
        </div>
        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="w90 mauto">
            <div class="left-title mb20">
              <p class="title ft18 bold">▶ 取数方便，查询性能高效</p>
            </div>
            <ul>
              <li class="about-point"> 
                <span class="dot"></span>提供了不同的连接方式对接多维数据库或者关系数据库
              </li>
              <li class="about-point"> 
                <span class="dot"></span>实现自助的跨库关联查询以及配置高速缓存加速查询性能
              </li>
            </ul>
          </div>
          <div class="w90 mauto mt20">
            <div class="left-title mb20">
              <p class="title ft18 bold">▶ 操作灵活，人人都是数据分析师</p>
            </div>
            <ul>
              <li class="about-point"> 
                <span class="dot"></span>提供了切片、切块、钻取以及行列互换等多种操作方式，使不同角色可基于自身所关注的维度进行灵活的数据分析
              </li>
              <li class="about-point"> 
                <span class="dot"></span>提供了丰富的图形分析，内置了多种计算分析，支持设置告警，支持以WORD、EXCEL、HTML等方式导出分析结果
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  
}
</script>
<style scoped>
/* ----------------------------------------------------------------
[ 06 About-section ]
-----------------------------------------------------------------*/
h2 {
    font-size: 33px;
    line-height: 48px;
    margin-bottom: 0px;
    font-weight: 700;
}

.about-bg {
  /* background-image: url('~@/assets/about-bg.png');
  background-repeat: no-repeat; */
  padding: 90px 0 45px 0;
}


.left-title {
  text-align: left;
}

.left-title .title {
  text-transform: capitalize;
}

.left-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  font-weight: 600;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  border: 2px solid #1559b7;
  display: inline-block;
  margin-right: 10px;
}

.about-point {
  font-weight: 600;
  padding-bottom: 15px;
}

.btn {
  border-radius: 8px;
  background-color: #ff8a41;
  margin: 40px 0 30px 0;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 12px 40px;
  width: 50%;
}

.btn:hover {
  background-color: #596dee;
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

/* ----------------------------------------------------------------
     [ End About-section ]
-----------------------------------------------------------------*/
</style>