<template>
  <section class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 相关案例 -</h2>
            <h4 class="sub-title">Related Cases</h4>
          </div>
        </div>
      </div>
      <div class="row">

        <div v-for="(item, index) in list" :key="index" class="col-lg-4 col-md-6 col-sm-10">
          <div class="blog-box wow bounceInLeft" :class="index%2 ? 'active' : ''">
            <div class="blog-img">
              <img :src="item.img" alt="blog-img">
            </div>
            <div class="blog-content">
              <h3 class="bold"><a href="#"> {{ item.title  }} </a></h3>
              <!-- <ul class="blog-meta-tag">
                <li> <span> Date : </span> 24.07.2021 </li>
                <li> <span> By : </span> <a href="#"> Admin </a></li>
              </ul> -->
              <p class="gray97" :title="item.content">{{ item.content  }}</p>
            </div>
            <div class="blog-btn">
              <a href="javascript:" @click="handleLink(item)">去了解 >></a>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '智慧校园管理平台',
          content: '融合教学、教务、安全、办公OA、学科资源、教师素质、学生综合发展等功能为一体',
          route: '/schoolProduct',
          img: '/img/zhoabg.jpg',
          type: '1'
        },
        {
          title: '校园隐患管理',
          content: '通过Web端或手机APP，可随时查看隐患的全流程闭环管理状态，每项隐患从上报、确认、负责指派到整改验收，整个治理流程流转都能直观可见，同时可结合数字孪生、数据可视化大屏技术，将隐患排查分布动态关联，实时展现风险管控结果',
          route: '/hiddenDangerProduct',
          img: '/img/yyyhgl.jpg',
          type: '1'
        },
        {
          title: '校园数字孪生',
          content: '通过3D场景搭建园区级、城市级的场景，实现场景化运营管理，完整呈现场景内楼宇及周边环境的建筑、道路、桥梁分布，从建筑物外部到内部结构实现多层级管理',
          route: '/digitalTwins',
          img: '/img/xyszls.png',
          type: '2'
        }
      ]
    }
  },
  methods: {
    handleLink(item) {
      let path = item.route;
      if (item.type === '1') {
        const routeLink = this.$router.resolve({ path: path });
        window.open(routeLink.href, '_blank');
      } else {
        this.$router.push({ path });
      }
    },
  }
}
</script>

<style scoped>
.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 11 Blogs-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding-top: 80px;
  padding-bottom: 80px;
}
.container {
  max-width: 1140px;
}
.blog-box {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  height: auto;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.blog-content {
  padding: 0 20px;
}

.blog-content h3 {
  margin-bottom: 10px;
  font-size: 18px;
}
.blog-content p {
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}

.blog-img {
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 30px;
}

.blog-img img {
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  max-width: 100%;
  height: 200px;
}

.blog-meta-tag  li span {
  color: #6e88e5;
}

.blog-meta-tag  li {
  display: inline;
  padding-right: 10px;
}

.blog-content ul {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
}

.blog-btn {
  width: 145px;
  height: 40px;
  background-color: #5a68ef;
  color: #FFF;
  display: block;
  text-align: center;
  margin-top: 25px;
  border-radius: 0px 8px 8px 0px;
  line-height: 2.5;
}

.blog-btn:hover {
  background-color: #ff8a41;
  color: #FFF;
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

.blog-box.active .blog-btn {
  background-color: #ff8a41;
  color: #FFF;
}

/* ----------------------------------------------------------------
     [ End Blogs-section ]
-----------------------------------------------------------------*/

</style>
