<template>
  <div class="colorlib-services colorlib-bg-white">
    <div class="container">
      <div class="row">

        <div
          v-for="(item, index) in list" :key="index"
          class="col-md-6 text-center animate-box wow bounceInLeft">

          <div 
            class="services"
            @click="jumpClick(item.hash)">
            <span class="icon"><i class="gray4 fa" :class="item.icon"></i></span>
            <div class="desc">
              <h3>{{ item.title  }}</h3>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
            <div class="mt40 w30 gray9 ft14 ptb5 radius8 mauto">
              <a href="javascript:" @click="jumpClick(item.hash)">去了解 >></a>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '医院综合管理',
          content: '医院管理系统是现代化医院运营的必要技术支撑和基础设施，实现医院管理系统的目的就是为了以更现代化、科学化、规范化的手段来加强医院的管理，提高医院的工作效率，改进医疗质量，从而树立现代医院的新形象，这也是未来医院发展的必然方向。该解决方案的实施将在整个医院建设企业级的计算机网络系统，并在其基础上构建企业级的应用系统，实现整个医院的人、财、物等各种信息的顺畅流通和高度共享，为全院的管理水平现代化和领导决策的准确化打下坚实的基础。该系统具有成熟、稳定、可靠、适用期长、扩充性好等特点，可以根据各医院各自的特点度身制作',
          icon: 'fa-handshake-o',
          hash: 'yyzhgl'
        },
        {
          title: '便民服务',
          content: '以出院病人的临床随访为主、单病种科研数据管理为辅的随访管理中心，结合医随APP，以消息推送的方式对病人进行随访提醒、院后病情变化跟踪和健康教育指导等一系列院后医疗服务，加强医患沟通，同事提高患者的复诊率，从而提升医院形象和服务质量',
          icon: 'fa-desktop',
          hash: 'yybmfu'
        }
      ]
    }
  },
  methods: {
    jumpClick(hash){
      // document.querySelector(`#${hash}`).scrollIntoView(true)
      document.querySelector(`#${hash}`).scrollIntoView({
        behavior: "smooth", 
        // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
        block: "center",
        // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
        inline: "center" 
        // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
      })
    }
  }
}
</script>

<style scoped>
.animate-box {
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #00000014;
}
.animate-box:hover {
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}
.animate-box:hover .icon {
  transform: scale(1.3);
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
}

.colorlib-services {
  padding: 60px 0 60px 0;
  clear: both; 
}
.colorlib-bg-white {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-left: none;
  border-right: none; 
}
.services {
  position: relative;
  z-index: 0;
  padding: 20px; 
}

.animate-box:before{
	position:absolute;
	content:"";
	left:0;
	bottom:0;
	height:3px;
	width:0%;
	background:#798eea;
	opacity:0;
	visibility:hidden;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.animate-box:hover:before{
	width:100%;
	opacity:1;
	visibility:visible;
}

@media screen and (max-width: 768px) {
  .services {
    margin-bottom: 3em; 
  } 
}
.services .icon {
  position: relative;
  margin-bottom: 30px;
  display: inline-block; 
}
.services .icon i {
  font-size: 30px;
  color: #798eea;
  z-index: 1; 
}
.services .desc h3 {
  font-size: 18px;
  font-weight: bold; 
  color: #303133;
  font-family: "Nunito", Arial, sans-serif;
  /* font-weight: 400; */
  margin: 0 0 20px 0;
  line-height: 1.3;
}
.services .desc p {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
  /* color: rgb(61, 61, 61); */
  color: #333;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>