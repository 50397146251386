<template>
  <section id="projectManage" class="ws-section-spacing about-bg">
    <div class="container">
      <div class="row about-content">

        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="w90 mauto">
            <div class="left-title">
              <p class="title ft30 bold">▶ 表单设计器 </p>
              <h4 class="sub-title">技术亮点：丰富字段类型、自定义布局样式、跨应用数据关联</h4>
            </div>
            <div>帮助你向数据库中的表输入数据,查询数据的一个友好界面!系统开发员会设计出许多表单,组合在一起,来实现用户对数据库的管理(输入,查询和显示都可以通过表单来实现)。像玩“乐高”一样自由搭建企业数据入口。丰富的字段类型、拖拽式的表单设计，自定义的表单样式，让你的企业管理如虎添翼～</div>
            <a href="javascript:;" class="btn" @click="$router.push({ path: '/formDesigner' })">查看详情 >></a>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 wow bounceInLeft">
          <div class="ht300 bb_shadow radius10 tc w90 mauto">
            <img class="h100 w100" src="/img/bdsjq.png" alt="img">
          </div>
        </div>

      </div>
    </div>
  </section>
</template>
<script>
export default {
  
}
</script>
<style scoped>
/* ----------------------------------------------------------------
[ 06 About-section ]
-----------------------------------------------------------------*/
h2 {
    font-size: 33px;
    line-height: 48px;
    margin-bottom: 0px;
    font-weight: 700;
}

.about-bg {
  /* background-image: url('~@/assets/about-bg.png');
  background-repeat: no-repeat; */
  padding: 90px 0 45px 0;
}


.left-title {
  text-align: left;
  padding-bottom: 30px;
}

.left-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.left-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  font-weight: 600;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  border: 2px solid #596dee;
  display: inline-block;
  margin-right: 10px;
}

.about-point {
  font-weight: 600;
  padding-bottom: 15px;
}

.btn {
  border-radius: 8px;
  background-color: #ff8a41;
  margin: 40px 0 30px 0;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 12px 40px;
  width: 50%;
}

.btn:hover {
  background-color: #596dee;
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

/* ----------------------------------------------------------------
     [ End About-section ]
-----------------------------------------------------------------*/
</style>